import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import CalibrationChartSearch from "components/CalibrationChartSearch/CalibrationChartSearch";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const DEVICE_TYPE = [
  { value: "HiPerForm_Plus", label: "HiPerForm plus" }, 
  { value: "Telos_Chiller", label: "Telos Chiller" }, 
  { value: "Telos_Tank_EU", label: "Telos Tank (EU)" }, 
  { value: "Telos_Tank_NA", label: "Telos Tank (NA)" }, 
  { value: "Nimbus", label: "Nimbus" }, 
  { value: "Stratus", label: "Stratus" }
];

const NO_OF_EVCS = ["1", "2", "3", "4", "5", "6"];

const INDUSTRY = [
  { value: "Brewing", label: "Brewing" }, 
  { value: "Dairy", label: "Dairy" }, 
  { value: "Bakery", label: "Baking" }, 
  { value: "Generic", label: "Other" }
];

const AddDeviceControlForm = memo(
  ({ show, close, dairy, addNewTank, edit, tank, editTank, dealerID }) => {
    const { t } = useTranslation();
    const schema = yup.object().shape({
      deviceName: yup.string().required(),
      deviceType: yup.string().required(),
      equipmentModelNo: yup.string(),
      noOfEVCs: yup.number().integer().typeError(t("evc_error")),
      processedSolution: yup.string().required(),
      industry: yup.string().required(),
    });

    const [processedSolutions, setProcessedSolutions] = useState([])
    const [disabled, setDisabled] = useState(false);
    const { register, errors, handleSubmit } = useForm({
      validationSchema: schema,
    });


    const onSubmit = async (data) => {
      const obj = {
        ...data,
        deviceSerialNumber: edit ? data.deviceSerialNumber : "",
        deviceRegistrationId: edit ? data.deviceRegistrationId : "",
        chilledSolution: "",
        isOnline: false,
        macAdd: edit ? data.macAdd : ""
      }
      setDisabled(true);
      if (edit) {
        await editTank(tank.id, obj);
        setDisabled(false);
      } else {
        addNewTank(obj);
        setDisabled(false);
      }
    };

    const setProcessedSolution = (value) => {
      if(value === "Dairy"){
        setProcessedSolutions(["Milk"]);
      }else if(value === "Brewing"){
        setProcessedSolutions(["Beer"]);
      }else if(value === "Bakery"){
        setProcessedSolutions(["Water"]);
      }else if(value === "Generic"){
        setProcessedSolutions(["Milk", "Beer", "Water", "Ethanol", "Yeast", "Eggs"]);
      }
    }

    const onChangeIndustry = (e) => {
      const { value } = e.target;
      setProcessedSolution(value)
    }

    useEffect(() => {
      if(tank && tank.id){
        setProcessedSolution(tank.industry)
      }
    }, [tank])

    return (
      <Modal
        show={show}
        onHide={close}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header className="bg-primary" closeButton>
            <Modal.Title className="lead text-capitalize text-white mb-0 font-weight-bold">
              {edit ? t('edit_device') : t('add_device')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <Row>
              <Col lg={6}>
                <Form.Group controlId="exampleForm.ControlSelect2">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("device_name")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="deviceName"
                    size="lg"
                    type="text"
                    ref={register()}
                    defaultValue={edit ? tank.deviceName : ""}
                    isInvalid={errors.deviceName}
                  />
                  {errors.deviceName && (
                    <Form.Control.Feedback type="invalid">
                      {t("required_field_error")}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="exampleForm.ControlSelect2">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("device_type")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="deviceType"
                    size="lg"
                    ref={register()}
                    defaultValue={edit ? tank.deviceType : ""}
                    isInvalid={errors.deviceType}
                  >
                    {DEVICE_TYPE.map(({ value, label }) => (
                      <option
                        value={value}
                      >
                        {label}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.deviceType && (
                    <Form.Control.Feedback type="invalid">
                      {t("required_field_error")}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group controlId="exampleForm.ControlSelect2">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("location_name")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    defaultValue={dairy}
                    readOnly
                  />
                  {errors.locationName && (
                    <Form.Control.Feedback type="invalid">
                      {t("required_field_error")}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="exampleForm.ControlSelect2">
                  <Form.Label className="text-capitalize">
                    {t("equipment_model_number")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="equipmentModelNo"
                    size="lg"
                    ref={register()}
                    defaultValue={edit ? tank.equipmentModelNo : ""}
                    isInvalid={!!errors.equipmentModelNo}
                  />

                  {errors.equipmentModelNo && (
                    <Form.Control.Feedback type="invalid">
                      {errors.equipmentModelNo.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="exampleForm.ControlSelect2">
                  <Form.Label className="text-capitalize">
                    {t("num_evcs")}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    type="number"
                    name="noOfEVCs"
                    size="lg"
                    ref={register()}
                    defaultValue={edit ? tank.noOfEVCs : 0}
                    isInvalid={!!errors.noOfEVCs}
                  >
                    {NO_OF_EVCS.map((item) => (
                      <option
                        value={item}
                        key={item}
                      >
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.noOfEVCs && (
                    <Form.Control.Feedback type="invalid">
                      {errors.noOfEVCs.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="exampleForm.ControlSelect2">
                  <Form.Label className="text-capitalize">
                    {t("Industry")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="industry"
                    size="lg"
                    ref={register()}
                    defaultValue={edit ? tank.industry : ""}
                    isInvalid={!!errors.industry}
                    onChange={onChangeIndustry}
                  >
                    <option value="" selected disabled></option>
                    {INDUSTRY.map(({ value, label }) => (
                      <option
                        value={value}
                      >
                        {label}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.industry && (
                    <Form.Control.Feedback type="invalid">
                      {t("required_field_error")}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="exampleForm.ControlSelect2">
                  <Form.Label className="text-capitalize">
                    {t("processed_solution")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="processedSolution"
                    size="lg"
                    ref={register()}
                    defaultValue={edit ? tank.processedSolution : ""}
                    isInvalid={!!errors.processedSolution}
                  >
                    {processedSolutions.map((item) => (
                      <option
                        value={item}
                        key={item}
                      >
                        {item}
                      </option>
                    ))}
                  </Form.Control>

                  {errors.processedSolution && (
                    <Form.Control.Feedback type="invalid">
                      {t("required_field_error")}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

            </Row>
          </Modal.Body>
          <Modal.Footer className="pt-0">
            <Button
              variant="primary"
              type="submit"
              className="text-capitalize"
              size="lg"
              disabled={disabled}
            >
              {edit ? t("update") : t("create")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
);

AddDeviceControlForm.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func.isRequired,
  dairy: PropTypes.string.isRequired,
  addNewTank: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  tank: PropTypes.any,
  editTank: PropTypes.func.isRequired,
};

AddDeviceControlForm.defaultProps = {
  show: false,
  edit: false,
  tank: {},
};

export default AddDeviceControlForm;

import React, { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import io from "../../actions/socket";
import { rootApi } from "../../axiosConfig";
import { Row, Col, Image, Table } from "react-bootstrap";
import ChillerControlIcon from "../../assets/images/chiller-control-panel-1.png";
import GeneralSetupIcon from "../../assets/images/general-setup.svg";
import _ from "lodash";
import { useParams } from "react-router";

const Settings = () => {
  const { dairyID, tankID } = useParams();
  const { t } = useTranslation();
  const [chillerSettings, setChillerSettings] = useState(true)
  const [generalSetup, setGeneralSetup] = useState(false)
  return (
    <div className="my-4 rounded px-4 py-3 live-alerts">
      <div className="mb-3">
        <div className="d-flex flex-column flex-sm-column flex-lg-row">
            <div className={chillerSettings ? "settings d-flex bg-light-blue" : "settings d-flex bg-white"} onClick={() => {
                setChillerSettings(true)
                setGeneralSetup(false)
            }}>
                <Image src={ChillerControlIcon} className="mr-2" />
                <span>Chiller Settings</span>
            </div>
            <div className={generalSetup ? "settings d-flex bg-light-blue" : "settings d-flex bg-white"} onClick={() => {
                setGeneralSetup(true)
                setChillerSettings(false)
            }}>
                <Image src={GeneralSetupIcon} className="mr-2" />
                <span>General Setup</span>
            </div>
        </div>
      </div>
      <div className="mb-3 mt-5">
        {
            chillerSettings && 
            <div className="d-flex flex-column flex-sm-column flex-lg-row justify-content-between">
                <Table bordered={true} className="chiller-setting-table table-bordered mr-5">
                    <tr className="chiller-setting-table-data">
                        <td className="d-flex justify-content-between">
                            <span>Pre-Start Cooling Cycle</span>
                            <span>30 or 60 min</span>
                        </td>
                    </tr>
                    <tr className="chiller-setting-table-data">
                        <td className="d-flex justify-content-between">
                            <span>Temperature Differential 1</span>
                            <span>2 to 4</span>
                        </td>
                    </tr>
                    <tr className="chiller-setting-table-data">
                        <td className="d-flex justify-content-between">
                            <span>Pre-Start Pumping</span>
                            <span>30 or 60 min</span>
                        </td>
                    </tr>
                    <tr className="chiller-setting-table-data">
                        <td className="d-flex justify-content-between">
                            <span>Temperature Differential 2</span>
                            <span>2 to 4</span>
                        </td>
                    </tr>
                </Table>
                <Table bordered={true} className="chiller-setting-table table-bordered">
                    <tr className="chiller-setting-table-data">
                        <td className="d-flex justify-content-between">
                            <span>Pre-Start Cooling Cycle</span>
                            <span>30 or 60 min</span>
                        </td>
                    </tr>
                    <tr className="chiller-setting-table-data">
                        <td className="d-flex justify-content-between">
                            <span>Temperature Differential 1</span>
                            <span>2 to 4</span>
                        </td>
                    </tr>
                    <tr className="chiller-setting-table-data">
                        <td className="d-flex justify-content-between">
                            <span>Pre-Start Pumping</span>
                            <span>30 or 60 min</span>
                        </td>
                    </tr>
                    <tr className="chiller-setting-table-data">
                        <td className="d-flex justify-content-between">
                            <span>Temperature Differential 2</span>
                            <span>2 to 4</span>
                        </td>
                    </tr>
                </Table>
            </div>
        }
      </div>
    </div>
  );
};

export default Settings;

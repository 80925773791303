import React, { memo, useEffect, useState, useCallback } from "react";
import {
  Row,
  Col,
  Image,
  Button,
  OverlayTrigger,
  Tooltip,
  Alert,
  Container,
  Table,
  Tab,
  Tabs
} from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";
import { useParams, withRouter } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TankCard from "../../components/TankCard";
import Tank from "../../assets/images/Tank002.png";
import TankTwo from "../../assets/images/EVC-fan-gif.gif";
import IconDownLoad from "../../assets/images/exportCsv.svg";
import Settings from "../../containers/Tank/Settings";
import io from "../../actions/socket";
import TanksLoader from "../../components/TankLoader";
import GraphLoader from "../../components/GraphLoader";
import RawRegisterLoader from "../../components/RawRegisterLoader"
import ParseRegisterLoader from "../../components/ParseRegisterLoader"
import { ReactComponent as BackArrow } from "../../assets/images/back-arrow.svg";
import LiveDataSideBar from "../../components/LiveDataContent";
import _ from "lodash";
import { useMediaQuery } from "react-responsive";
import EVC from "../../assets/images/EVC-fan-gif.gif";

import {
  getTanks,
  fetchDairy as fetchFarmUsers,
  getTankHPFandEVCId,
  getTankCHILLERandEVCId,
  getLastAgitatedValue,
  fetchGraph,
  fetchGraphChiller,
  fetchDairy,
  addTank,
  addNewChillerController,
  deleteTank,
  deleteChiller,
  updateTank,
  updateChiller,
  getTankById,
  getChillerById,
  getUser,
  getChillers,
  getTankAlerts,
  getChillerAlerts,
  fetchConfigs,
  dragDropTank,
  dragDropChiller
} from "../../actions/actions";
import addTankIcon from "../../assets/images/add-tank-icon.svg";
import downCaret from "../../assets/images/date-box-caret.svg";
import reloadIcon from "../../assets/images/reload-icon.svg";
import Dropdown from "../../components/CustomDropdown";
import AddTankForm from "../../forms/TankForm/AddTankForm";
import AddDeviceControlForm from "../../forms/DeviceControlForm/AddDeviceControlForm";
import Toaster from "../../components/Toaster";
import volumeIcon from "../../assets/images/Volume.svg";
import thermometerIcon from "../../assets/images/thermometer.svg";
import pipelineTempIcon from "../../assets/images/pipeline-temp.svg";
import valveIcon from "../../assets/images/EVC-valve.svg";
import hspIcon from "../../assets/images/HSP-Pressure.svg";
import lspIcon from "../../assets/images/LSP-Pressure.svg";
import subCoolTempIcon from "../../assets/images/sub-cool-temp.svg";
import tankVerticalIcon from "../../assets/images/vertical-tank.svg";
import horizontalTank from "../../assets/images/tank-new.png";
import threeLineIcon from "../../assets/images/menu.svg";
import plusIcon from "../../assets/images/plusIcon.svg";

import {
  status_tank as statusTank,
  tank_states_timer as tankStatesTimer,
} from "../../config";
import statusImgCooling from "../../assets/images/cool-icon.svg";
import statusImgWashing from "../../assets/images/washing.png";
import standByIcon from "../../assets/images/Standby_Mode.png";
import settingsIcon from "../../assets/images/settings.png";
import diagnosticIcon from "../../assets/images/Diagnostics.png";
import LiveAlertsNotifications from "../../containers/Tank/LiveAlertsNotifications";
import AlertIcon from "../../assets/images/alert-icon.svg";
import SaturatedSuctionIcon from "../../assets/images/Group 3757.svg";
import fanSpeedIcon from "../../assets/images/Group 3861.svg";
import CondensingIcon from "../../assets/images/noun-temperature-3208741.svg";
import PromptModel from "../../components/PromptModel";
import ExportCsvForm from "../../forms/ExportCsvForm/ExportCsv";
import NoDataAvailable from "../../components/NoDataAvailable";
import NoTankDataAvailable from "../../components/NoTankDataAvailable";
import TankDevices from "../../components/TankDevices/TankDevices";
import Evc from "./EVC";
import Hpf from "./HPF";
import Chiller from "./CHILLER"
import { rootApi } from "../../axiosConfig";

import { sensors, ranges, secondsToHourMin } from "../../actions/passwordRegex";
import {
  getTempAsPerSelectedUnitSystem,
  getPressureAsPerSelectedUnitSystem,
  getVolumeAsPerSelectedUnitSystem,
  getStringFromDecimalToBinary
} from "../../helpers";

const GraphTypeWrapper = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
  img {
    position: relative;
    width: 35px;
    cursor: pointer;
  }
`;
const GraphControlDiv = styled.div`
  align-self: center;
  display: flex;
  justify-content: flex-end;
  flex: auto;
  div.pause {
    img {
      position: relative;
      top: -1px;
    }
  }
`;
const GraphTypeSubWrapper = styled.div`
  padding: 10px;
  margin-right: 10px;
  border-bottom: ${({ active }) => (active ? "3px solid #2c61a5" : "none")};
  background-color: ${({ active }) => (active ? "#f0f6ff" : "white")};
  display: inline-block;
`;

const TankListing = memo((props) => {
  const { t } = useTranslation();
  const user = getUser();
  const [agitatedTime, setAgitatedTime] = useState(null);
  const [rawRegisters, setRawRegisters] = useState({});
  const [registerValues, setRegisterValues] = useState({});
  const [isLoadingRegisterValues, setIsLoadingRegisterValues] = useState(false);
  const configs = JSON.parse(localStorage.getItem("configs"));
  const [config, setConfig] = useState(configs);
  const [hpfGraphData, setHPFGraphData] = useState("");
  const [chillerGraphData, setChillerGraphData] = useState("");
  const [evcGraphData, setEVCGraphData] = useState(["", "", ""]);
  const [liveData, setLiveData] = useState({
    temperature: "--",
    volume: "--",
    pipelineTemperature: "--",
    hspValue: "--",
    lspValue: "--",
    valvePos: "--",
    subCoolTemp: "--",
    saturatedSuctionTemp: "--",
    condensingTemp: "--",
    fanSpeed: "--",
    voltageInputStatus: "--",
    probeSensorStatus: "--",
    pump1: null,
    pump2: null
  });
  const [showAddTank, setShowAddTank] = useState(false);
  const [isTankRegistered, setIsTankRegistered] = useState(true);
  const [customDates, setCustomDates] = useState([
    new Date(ranges.Yesterday[0]).toISOString(),
    new Date().toISOString(),
  ]);
  const { dairyID, tankID } = useParams();
  const [tanks, setTanks] = useState([]);
  const [dairyName, setDairyName] = useState("");
  const [dairyAdmin, setDairyAdmin] = useState(false);
  const [dealerID, setDealerID] = useState("");
  const [currentEditTank, setCurrentEditTank] = useState({});
  const [isTankEdit, setIsTankEdit] = useState(false);
  const [deviceType, setDeviceType] = useState("CHILLER");
  const [deviceIdObj, setDeviceIdObj] = useState(null);
  const [reloadCount, setReloadCount] = useState(0);
  const [autoReloadInterval, setAutoReloadInterval] = useState(null);
  const [relIntVar, setRelIntVar] = useState(null);
  const [graphLoader, setGraphLoader] = useState(false);
  const [currentDatetime, setCurrentDatetime] = useState(new Date());
  const [dateTimeIntVar, setDateTimeIntVar] = useState(null);
  const [deviceId, setDeviceId] = useState("");
  const [tankStatusCode, setTankStatusCode] = useState("");
  const [tankStatusObj, setTankStatusObj] = useState(null);
  const [tankDeleteAlert, setTankDeleteAlert] = useState(false);
  const [tankDeleteIndex, setTankDeleteIndex] = useState(null);
  const [tanksLoader, setTanksLoader] = useState(false);
  const [drag, setDrag] = useState(false);
  const [liveAlerts, setLiveAlerts] = useState(false);
  const [isShowingRawRegisterValues, showRawRegisterValues] = useState(false);
  const [isShowingRawRegisterValuesLoading, setIsShowingRawRegisterValuesLoading] = useState(false);
  const [isShowingRegisterValues, showRegisterValues] = useState(false);
  const [showAnnotation, setShowAnnotation] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [washData, setWashData] = useState([]);
  const [currentTankData, setCurrentTankData] = useState({});

  /** Define Export csv state */
  const [show, setShow] = useState(false);
  const [exportDeviceIds, setExportDeviceIds] = useState({});
  const [tankType, setTankType] = useState(null);
  const [manualAnnotation, setManualAnnotation] = useState(false);
  const [configId, setConfigId] = useState(null);
  // const [redIndicator, setRedIndicator] = useState(false);

  const [unResolvedDevices, setUnResolvedDevices] = useState([]);
  const [tankAlert, setTankAlert] = useState([]);

  const [tankSettings, setTankSettings] = useState(false);
  const setRawRegisterValue = (flag) => {
    showRawRegisterValues(flag)
    showRegisterValues(!flag)
  }

  const setRegisterValuesFlag = (flag) => {
    showRegisterValues(flag)
    showRawRegisterValues(!flag)
  }

  /** Fetch config Api call */
  useEffect(() => {
    fetchConfigs()
      .then((response) => {
        if (response.status === 200) {
          const item = response.data.find((i) =>
            i.key === "MANUAL_ANNOTATION" ? i : response.data
          );
          setConfigId(item.id);
          const sensorItems = response.data;
          const sensor = sensorItems.filter((sensorData) => sensorData.unit !== "");
          localStorage.setItem("configs", JSON.stringify(sensor));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  const getGraphData = async () => {
    setGraphLoader(true);
    let devID = "";
    if (!deviceId) {
      if (!deviceIdObj) {
        const { data } = deviceType === "HPF" ? 
                          await getTankHPFandEVCId(tankID)
                          : await getTankCHILLERandEVCId(tankID);
        setExportDeviceIds(data);
        [devID] = data[deviceType];
      } else {
        [devID] = deviceIdObj[deviceType];
      }
    } else {
      devID = deviceId;
    }
    if (deviceType === "HPF") {
      try {
        const value = await fetchGraph(
          "Custom",
          deviceType,
          sensors.HPF,
          tankID,
          devID,
          customDates[0],
          customDates[1]
        );
        setHPFGraphData(value.data.graphData);
        setWashData(value.data.washStatus);
        setGraphLoader(false);
        return;
      } catch (error) {
        console.log(error);
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong")} />
        );
      }
    } else if (deviceType === "CHILLER") {
      try {
        const value = await fetchGraphChiller(
          "Custom",
          "CHILLER",
          sensors.CHILLER,
          tankID,
          devID,
          customDates[0],
          customDates[1]
        );
        setChillerGraphData(value.data.graphData);
        // setWashData(value.data.washStatus);
        setGraphLoader(false);
        return;
      } catch (error) {
        console.log(error);
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong")} />
        );
      }
    }
    for (let i = 0; i < sensors.EVC.length; i += 1) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const res = deviceType === "HPF" ? 
          await fetchGraph(
            "Custom",
            "EVC",
            sensors.EVC[i],
            tankID,
            devID,
            customDates[0],
            customDates[1]
          )
          : await fetchGraphChiller(
            "Custom",
            "EVC",
            sensors.EVC[i],
            tankID,
            devID,
            customDates[0],
            customDates[1]
          )
        evcGraphData[i] = res.data.graphData;
      } catch (error) {
        console.log(error);
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong")} />
        );
      }
    }
    setEVCGraphData([...evcGraphData]);
    setGraphLoader(false);
  };

  const getTankDetail = async () => {
    setGraphLoader(true);
    setAgitatedTime(null);
    setDeviceIdObj(null);
    let tempDeviceID;
    if(deviceType === "HPF"){
      const { data } = await getTankHPFandEVCId(tankID);
      setDeviceType("HPF")
      if (data.HPF.length > 0) {
        const result = await getLastAgitatedValue(tankID, data.HPF[0]);
        setAgitatedTime(result?.data[0]?.time);
        setIsTankRegistered(true);
        let HPF = [];
        if (data.HPF) {
          HPF[0] = data.HPF[0];
        }
        let EVC = [];
        if (data.EVC) {
          EVC = data.EVC;
        }
        setDeviceIdObj({ HPF, EVC });
        setDeviceId(data.HPF[0]);
        tempDeviceID = data.HPF[0];
      } else {
        const { data: tankData } = await getTankById(tankID);
        if (tankData.registrationId) {
          setIsTankRegistered(false);
        } else {
          tempDeviceID = tankData.id;
          setIsTankRegistered(true);
        }
      }
      try {
        const value = await fetchGraph(
          "Custom",
          "HPF",
          sensors.HPF,
          tempDeviceID,
          data.HPF[0],
          customDates[0],
          customDates[1]
        );
        setHPFGraphData(value.data.graphData);
        setWashData(value.data.washStatus);
      } catch (error) {
        console.log(error);
        toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
      }
    } else if(deviceType === "CHILLER") {
        const { data } = await getTankCHILLERandEVCId(tankID);
        setDeviceType("CHILLER");
        if (data.CHILLER.length > 0) {
          setIsTankRegistered(true);
          let CHILLER = [];
          if (data.CHILLER) {
            CHILLER[0] = data.CHILLER[0];
          }
          let EVC = [];
          if (data.EVC) {
            EVC = data.EVC;
          }
          setDeviceIdObj({ CHILLER, EVC });
          setDeviceId(data.CHILLER[0]);
          tempDeviceID = data.CHILLER[0];
        } else {
          const { data: tankData } = await getChillerById(tankID);
          setDeviceId(tankData.deviceSerialNumber)
          if (tankData.deviceRegistrationId) {
            setIsTankRegistered(false);
          } else {
            tempDeviceID = tankData.id;
            setIsTankRegistered(true);
          }
        }
        try {
          const value = await fetchGraphChiller(
            "Custom",
            "CHILLER",
            sensors.CHILLER,
            tankID,
            tempDeviceID,
            customDates[0],
            customDates[1]
          );
          setChillerGraphData(value.data.graphData);
        } catch (error) {
          console.log(error);
          toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
        }
    }
    setGraphLoader(false);
  };

  const onBeforeDragStart = () => {
    setDrag(true);
  };

  /** Get index when drag and drop tank card */
  const getIndex = (list, sourceIndex, destIndex) => {
    if (destIndex === 0) {
      return "0" + list[destIndex].index;
    } else if (destIndex === list.length - 1) {
      return list[destIndex].index + "z";
    } else {
      return sourceIndex < destIndex
        ? list[destIndex].index + list[destIndex + 1].index
        : list[destIndex - 1].index + list[destIndex].index;
    }
  };

  /** Index wise sort Item */
  function sortItems(obj) {
    return obj.sort((item1, item2) => {
      let tmpItem1 = item1.index;
      let tmpItem2 = item2.index;
      if (tmpItem1 < tmpItem2) {
        return -1;
      }
      if (tmpItem1 > tmpItem2) {
        return 1;
      }
      return 0;
    });
  }
  /** Ondrag function call */
  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;
    // Added condition that if we are dropping out side of droppable context, we can not do
    if (!destination) {
      setDrag(false);
      return;
    }
    let tempTankItem = tanks;
    let index = getIndex(tempTankItem, source.index, destination.index);
    const tempObj = tempTankItem[source.index];
    let item = deviceType === "HPF" ? { tankName: tempObj.tankName, index: index } : { deviceName: tempObj.deviceName, index: index };
    try {
    const result = deviceType === "HPF" ? await dragDropTank(draggableId, item) : await dragDropChiller(draggableId, item)
    if (result.status === 200) {
      if(deviceType === "HPF") {
        getTanks(dairyID).then((data) => {
          let tempStaticItems = sortItems(data.data);
          setTanks(tempStaticItems);
          setTanksLoader(false);
        });
      } else {
        getChillers(dairyID).then((data) => {
          let tempStaticItems = sortItems(data.data);
          setTanks(tempStaticItems);
          setTanksLoader(false);
        });
      }
    }
      } catch(err) {
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong")} />
        );
      };
    setDrag(false);
    if (destination.index === source.index) {
      return;
    }
    const newTankOrder = Array.from(tanks);
    const [replaceItem] = newTankOrder.splice(source.index, 1);
    newTankOrder.splice(destination.index, 0, replaceItem);
    setTanks([...newTankOrder]);
  };

  /** Get index on post tank data function */
  const getIndexOnCreate = (listOfTanks) => {
    return listOfTanks.length === 0
      ? "a"
      : listOfTanks[listOfTanks.length - 1].index + "z";
  };

  /** Add new Tank functionality */
  const addNewTank = async (data) => {
    const { history, location } = props;
    let index = getIndexOnCreate(tanks);
    const temp = { ...data, index: index, dairy: dairyID, locationName: dairyID };
    try {
      const res = deviceType === "HPF" ? await addTank(temp) : await addNewChillerController(temp);
      if ((deviceType === "HPF" && dairyID === res.data.dairy.id) || (deviceType === "CHILLER" && dairyID === res.data.locationName.id)) {
        if (tanks.length === 0) {
          let url = location.pathname.split("/");
          url.splice();
          url.push(res.data.id);
          url = url.join("/");
          history.replace(url);
        }
        setTanks([...tanks, res.data]);
        setShowAddTank(false);
        toast.dark(
          <Toaster
            className="text-capitalize"
            icon="notify"
            message={deviceType === "HPF" ? t("tank_added") : t("chiller_added")}
          />,
          { autoClose: 3000, hideProgressBar: true }
        );
        if (tanks.length === 0) {
          let url = location.pathname.split("/");
          url.splice();
          url.push(res.data.id);
          url = url.join("/");
          history.replace(url);
        }
      }
    } catch (err) {
      console.log(err);
      toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
    }
  };
  /** Edit Tank functionality */
  const editTank = async (id, data) => {
    const temp = { ...data, dairy: dairyID, locationName: dairyID };
    try {
      const res = deviceType === "HPF" ? await updateTank(id, temp) : await updateChiller(id, temp);
      if (res.status === 200) {
        setTanks(tanks.map((i) => (i.id === res.data.id ? res.data : i)));
        setShowAddTank(false);
        toast.dark(
          <Toaster
            className="text-capitalize"
            icon="notify"
            message={deviceType === "HPF" ? t("tank_updated") : t("chiller_updated")}
          />,
          { autoClose: 3000, hideProgressBar: true }
        );
      }
    } catch (error) {
      console.log(error);
      toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
    }
  };

  /** Remove tank functionality  */
  const removeTank = async (index) => {
    const { location, history } = props;
    const currentTankId = tanks[index].id;
    try {
      deviceType === "HPF" ? await deleteTank(currentTankId) : await deleteChiller(currentTankId);
      const res = deviceType === "HPF" ? await getTanks(dairyID) : await getChillers(dairyID);
      setTankDeleteAlert(false);
      let tempStaticItems = sortItems(res.data);
      setTanks(tempStaticItems);
      if (tempStaticItems.length === 0) {
        let url = location.pathname.split("/");
        url.splice();
        url.length--;
        history.replace(url.join("/"));
      } else {
        setCurrentTankData({})
        setChillerGraphData("")
        setHPFGraphData("")
        setEVCGraphData(["", "", ""])
        setDeviceIdObj(null)
        setLiveData({
          temperature: "--",
          volume: "--",
          pipelineTemperature: "--",
          hspValue: "--",
          lspValue: "--",
          valvePos: "--",
          subCoolTemp: "--",
          saturatedSuctionTemp: "--",
          condensingTemp: "--",
          fanSpeed: "--",
          voltageInputStatus: "--",
          probeSensorStatus: "--",
          pump1: null,
          pump2: null
        })
      }
      toast.dark(
        <Toaster
          className="text-capitalize"
          icon="notify"
          message={deviceType === "HPF" ? t("tank_deleted") : t("chiller_deleted")}
        />,
        { autoClose: 3000, hideProgressBar: true }
      );
    } catch (error) {
      console.log(error);
      toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
    }
  };

  const handleDateCallback = (start, end, label) => {
    customDates[0] = start.toISOString();
    customDates[1] = end.toISOString();
    setCustomDates([...customDates]);
  };

  const tick = () => {
    // setCurrentDatetime(new Date());
    customDates[0] = new Date(ranges.Yesterday[0]).toISOString();
    customDates[1] = new Date().toISOString();
    setCurrentDatetime([...customDates]);
  };

  const setStatusTank = () => {
    let image;
    let textOne;
    let textTwo;
    let textThree;
    let color = "black";
    let hpfFan = "0";
    let mainTimer;
    let timerVal;
    let pump1;
    let pump2;
    if (
      currentTankData &&
      currentTankData["CHILLER"] &&
      Object.keys(currentTankData.CHILLER).length
    ) {
      pump1 = getStringFromDecimalToBinary(
        currentTankData.CHILLER[Object.keys(currentTankData.CHILLER)[0]]
          .CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS,
        11
      );
      pump2 = getStringFromDecimalToBinary(
        currentTankData.CHILLER[Object.keys(currentTankData.CHILLER)[0]]
          .CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS,
        13
      );
    }
    if (tankStatusCode !== "") {
      if (statusTank[tankStatusCode].indexOf("Washing") > -1) {
        image = statusImgWashing;
        mainTimer = (
          <span className="timerTag">
            (
            {secondsToHourMin(
              currentTankData.HPF[Object.keys(currentTankData.HPF)[0]]
                .HPF_INPUT_REG_WASH_TIME_REMAINING
            )}
            )
          </span>
        );
      } else if (statusTank[tankStatusCode].indexOf("Cooling") > -1) {
        textOne = t("cooling");
        image = statusImgCooling;
        color = "#2c61a5";
        const minToSec =
          currentTankData.HPF[Object.keys(currentTankData.HPF)[0]]
            .HPF_INPUT_REG_COOL_CYCLE_TIMER * 60;

        mainTimer =
          minToSec !== "" && tankStatusCode > 2002 ? (
            <span className="timerTag">
              {minToSec === "" ? "" : secondsToHourMin(minToSec)}
            </span>
          ) : null;
      } else if (statusTank[tankStatusCode].indexOf("Standby") > -1) {
        image = standByIcon;
      } else if (
        statusTank[tankStatusCode].indexOf("Editing Parameters") > -1 ||
        statusTank[tankStatusCode].indexOf("Setup") > -1
      ) {
        image = settingsIcon;
      } else if (statusTank[tankStatusCode] === "Diagnostic") {
        image = diagnosticIcon;
      }
      hpfFan =
        currentTankData.HPF[Object.keys(currentTankData.HPF)[0]]
          .AGITATOR_MOTOR_RELAY;

      textOne = t(statusTank[tankStatusCode].split(" - ")[0]);
      textTwo = t(statusTank[tankStatusCode].split(" - ")[1]);
      textThree = t(statusTank[tankStatusCode].split(" - ")[2]);
      if (currentTankData !== null && tankStatusCode !== "") {
        let timerName = tankStatesTimer[tankStatusCode.toString()];
        timerVal =
          currentTankData.HPF[Object.keys(currentTankData.HPF)[0]][timerName];
      }
    } else {
      mainTimer = "";
    }

    const temp = {
      image,
      textOne,
      textTwo,
      textThree,
      color,
      hpfFan,
      mainTimer,
      timerVal,
      pump1,
      pump2,
    };
    setTankStatusObj(temp);
  };

  const getAlerts = async () => {
    try {
      const startTime = Date.parse(customDates[0]);
      const endTime = Date.parse(customDates[1]);
      const res =  deviceType === "HPF" ? await getTankAlerts(tankID, startTime, endTime) : await getChillerAlerts(tankID, startTime, endTime);
      setAlerts(res.data);
    } catch (error) {
      toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
    }
  };

  useEffect(() => {
    if (tankID) {
      getTankDetail();
      getAlerts();
      fetchFarmUsers(dairyID)
      .then((response) => {
        const result = response?.data?.farmUsers.some(item => item.isActive === true && item.role === "DairyAdmin");
        setDairyAdmin(result);
      })
      .catch((err) => {
        console.error(err);
        toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
      });
    }
  }, [tankID]);

  useEffect(() => {
    setLiveData({
      temperature: "--",
      volume: "--",
      pipelineTemperature: "--",
      hspValue: "--",
      lspValue: "--",
      valvePos: "--",
      subCoolTemp: "--",
      saturatedSuctionTemp: "--",
      condensingTemp: "--",
      fanSpeed: "--",
      voltageInputStatus: "--",
      probeSensorStatus: "--",
      pump1: null,
      pump2: null
    });
    setManualAnnotation(false);
    setShowAnnotation(false);
    setTankStatusObj(null);
    io.socket.on(`influxData:${tankID}`, (data) => {
      let statusNumber = "";
      let temp;
      if (deviceType === "CHILLER" && data?.CHILLER) {
        const temperature =
          data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_TEMP_1;
        
        const pump1 = getStringFromDecimalToBinary(data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS, 11);
        const pump2 = getStringFromDecimalToBinary(data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS, 13);
        const voltageInputStatus = getStringFromDecimalToBinary(data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_VOLTAGE_INPUT_STATUS, 0);
        const probeSensorStatus = getStringFromDecimalToBinary(data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_PROBE_SENSOR_STATUS, 0);
        temp = {
          temperature,
          pump1,
          pump2,
          voltageInputStatus,
          probeSensorStatus
        };
      }
      if (deviceType === "HPF" && data?.HPF) {
        statusNumber =
        data.HPF[Object.keys(data.HPF)[0]].HPF_INPUT_REG_MAIN_STATE;
        const temperature =
          data.HPF[Object.keys(data.HPF)[0]].HPF_INPUT_REG_TEMP1;
        const volume =
          data.HPF[Object.keys(data.HPF)[0]]
            .HPF_INPUT_REG_VOLUME_4TO20MA_READING ||
            Number(
              data.HPF[Object.keys(data.HPF)[0]]
                .HPF_INPUT_REG_VOLUME_4TO20MA_READING
            ) === 0
            ? data.HPF[Object.keys(data.HPF)[0]]
              .HPF_INPUT_REG_VOLUME_4TO20MA_READING
            : "--";
        const pipelineTemperature =
          data.HPF[Object.keys(data.HPF)[0]].HPF_INPUT_REG_TEMP2;
        temp = {
          temperature,
          volume,
          pipelineTemperature,
        };
      }
      if (deviceType === "EVC" && data?.EVC) {
        const hspValue =
          data?.EVC[deviceId || Object.keys(data.EVC)[0]]
            ?.EVC_INPUT_REG_HIGH_SIDE_PRESSURE;

        const lspValue =
          data?.EVC[deviceId || Object.keys(data.EVC)[0]]
            ?.EVC_INPUT_REG_LOW_SIDE_PRESSURE;

        const valvePos =
          data.EVC[deviceId || Object.keys(data.EVC)[0]]
            ?.EVC_INPUT_REG_VALVE_POS;

        const subCoolTemp =
          data.EVC[deviceId || Object.keys(data.EVC)[0]]
            ?.EVC_INPUT_REG_SUBCOOL_TEMP;

        const saturatedSuctionTemp =
          data.EVC[deviceId || Object.keys(data.EVC)[0]]
            ?.EVC_INPUT_REG_LO_SAT_TEMP;

        const condensingTemp =
          data.EVC[deviceId || Object.keys(data.EVC)[0]]
            ?.EVC_INPUT_REG_HI_SAT_TEMP;

        const fanSpeed =
          data.EVC[deviceId || Object.keys(data.EVC)[0]]
            ?.EVC_INPUT_REG_FAN_OUTPUT;

        temp = {
          subCoolTemp,
          hspValue,
          condensingTemp,
          lspValue,
          saturatedSuctionTemp,
          valvePos,
          fanSpeed
        };
      }
      setCurrentTankData(data);
      setTankStatusCode(statusNumber);
      setLiveData({ ...liveData, ...temp });
      setTankType(data.tankType);
    });
    io.socket.on();
    if(deviceType === "HPF") {
      io.socket.on(`alerthistory:manual:${dairyID}`, (data) => {
        /** Display live alerts in devices */
        let resolvedDevices = [];
        if (data.event === "update") {
          resolvedDevices = data.data.map((i) => {
            if (i.isResolved) {
              return i.deviceID;
            } else {
              return "";
            }
          });
          resolvedDevices = resolvedDevices.filter(Boolean);
          setUnResolvedDevices((items) => {
            let tmpItems = items.filter(function (el, index, arr) {
              return index == arr.indexOf(el);
            });
            return tmpItems.filter((i) => !resolvedDevices.includes(i));
          });
        } else {
          setUnResolvedDevices((items) => {
            return [...items, data.data.deviceID];
          });
        }
        /** Graph reloaded when alerts display */
        let alerts = [];
        if (data.event === "update") {
          alerts = data.data.filter((el) => el.tank === tankID);
        } else {
          alerts.push(data.data);
        }
        if (alerts.length > 0 && data.tank === tankID) {
          setAlerts((prev) => [...prev, ...alerts]);
          getGraphData();
        }
        /** function call when socket connection generate */
        updateTankAlert();
      });
    } else {
      io.socket.on(`chilleralerthistory:manual:${dairyID}`, (data) => {
        /** Display live alerts in devices */
        let resolvedDevices = [];
        if (data.event === "update") {
          resolvedDevices = data.data.map((i) => {
            if (i.isResolved) {
              return i.deviceID;
            } else {
              return "";
            }
          });
          resolvedDevices = resolvedDevices.filter(Boolean);
          setUnResolvedDevices((items) => {
            let tmpItems = items.filter(function (el, index, arr) {
              return index == arr.indexOf(el);
            });
            return tmpItems.filter((i) => !resolvedDevices.includes(i));
          });
        } else {
          setUnResolvedDevices((items) => {
            return [...items, data.data.deviceID];
          });
        }
        /** Graph reloaded when alerts display */
        let alerts = [];
        if (data.event === "update") {
          alerts = data.data.filter((el) => el.tank === tankID);
        } else {
          alerts.push(data.data);
        }
        if (alerts.length > 0 && data.tank === tankID) {
          setAlerts((prev) => [...prev, ...alerts]);
          getGraphData();
        }
        /** function call when socket connection generate */
        updateTankAlert();
      });
    }
    return () => {
      io.socket.removeAllListeners();
    };
  }, [tankID, deviceId]);

  // Get register and raw register live value when isShowingRawRegisterValues == true
  useEffect(() => {
    setIsShowingRawRegisterValuesLoading(true)
    if (isShowingRawRegisterValues === true) {
      io.socket.on(`rawRegister:${tankID}`, (data) => {
        setRawRegisters(data);
        setIsShowingRawRegisterValuesLoading(false)
      });
    }
    // Destroy socket connection on component unmount
    return () => {
      io.socket.removeAllListeners();
    };
  }, [isShowingRawRegisterValues]);


  // Get register and raw register live value when isShowingRawRegisterValues == true
  useEffect(() => {
    if (isShowingRegisterValues === true) {

      // establish socket connection
      setIsLoadingRegisterValues(true);
      io.socket.on(`influxData:${tankID}`, (data) => {
        setRegisterValues(data);
        setIsLoadingRegisterValues(false);
      });
    }
    // Destroy socket connection on component unmount
    return () => {
      io.socket.removeAllListeners();
    };
  }, [isShowingRegisterValues]);

  /** Show red Indicator in tank card and alert icon */
  const updateTankAlert = useCallback(() => {
    if(deviceType === "HPF") {
      io.socket.get(
        `/api/alerthistories?where={"and":[{"alert":null},{"dairy":"${dairyID}"},{"deviceType":{"!=":""}},{"isResolved":false}, {"configKey":{ "!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`,
        (data) => {
          let tankIds = data.map((i) => i.tank.id);
          setTankAlert(tankIds);
        }
      );
    } else {
      io.socket.get(
        `/api/chilleralerthistories?where={"and":[{"chillerAlert":null},{"location":"${dairyID}"},{"deviceType":{"!=":""}},{"isResolved":false}, {"configKey":{ "!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`,
        (data) => {
          let tankIds = data.map((i) => i.id);
          setTankAlert(tankIds);
        }
      );
    }
  });

  useEffect(updateTankAlert, []);

  useEffect(() => {
    setStatusTank();
  }, [tankID, tankStatusCode, currentTankData]);

  useEffect(() => {
    const { location, history } = props;
    setTanksLoader(true);
    if(deviceType === "HPF") {
      getTanks(dairyID)
        .then((data) => {
          let tempStaticItems = sortItems(data.data);
          setTanks(tempStaticItems);
          setTanksLoader(false);
          if (data.data.length !== 0 && !tankID) {
            let url = location.pathname.split("/");
            url.splice();
            url.push(data.data[0]?.id);
            if (data.data[0]?.id) {
              url = url.join("/");
            }
            history.replace(url);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.dark(
            <Toaster icon="error" message={t("something_went_wrong")} />
          );
        });
    } else {
        getChillers(dairyID)
          .then((data) => {
            let tempStaticItems = sortItems(data.data);
            setTanks(tempStaticItems);
            setTanksLoader(false);
            if (data.data.length !== 0 && !tankID) {
              let url = location.pathname.split("/");
              url.splice();
              url.push(data.data[0]?.id);
              if (data.data[0]?.id) {
                url = url.join("/");
              }
              history.replace(url);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.dark(
              <Toaster icon="error" message={t("something_went_wrong")} />
            );
          });
    }

  }, [dairyID]);

  useEffect(() => {
    fetchDairy(dairyID)
      .then((data) => {
        setDairyName(data.data.name);
        setDealerID(data.data.createdBy?.id)
      })
      .catch((err) => {
        console.log(err);
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong")} />
        );
      });
  }, [dairyID]);

  useEffect(() => {
    getGraphData();
    setTankStatusObj(null);
  }, [tankID, deviceId]);

  useEffect(() => {
    if (reloadCount !== 0) {
      getGraphData();
      getAlerts();
    }
  }, [reloadCount]);

  /** Interval button in graph  */
  useEffect(() => {
    if (autoReloadInterval) {
      if (relIntVar) {
        clearInterval(relIntVar);
      }
      setRelIntVar(
        setInterval(() => {
          tick();
          getGraphData();
          getAlerts();
        }, autoReloadInterval * 1000)
      );
    } else if (relIntVar) {
      clearInterval(relIntVar);
    }
    return () => {
      if (relIntVar) {
        clearInterval(relIntVar);
      }
    };
  }, [autoReloadInterval]);

  useEffect(() => {
    setDateTimeIntVar(setInterval(tick, 10000));
    return () => {
      clearInterval(dateTimeIntVar);
    };
  }, []);

  const liveDataHelper = {
    CHILLER: [
      // {
      //   icon: volumeIcon,
      //   name: t("tank_volume"),
      //   value: getVolumeAsPerSelectedUnitSystem(Number(liveData.volume)).volume,
      //   unit: getVolumeAsPerSelectedUnitSystem(Number(liveData.volume))
      //     .volumeUnit,
      // },
      {
        icon: thermometerIcon,
        name: t("tank_temperature"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.temperature))
          .temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.temperature))
          .tempUnit,
      },
      {
        icon: SaturatedSuctionIcon,
        name: t("voltage_input_status"),
        value: liveData.voltageInputStatus ? "ON" : "OFF",
        unit: liveData.voltageInputStatus,
        isLabel: true
      },
      {
        icon: hspIcon,
        name: t("probe_sensor_status"),
        value: liveData.probeSensorStatus ? "PRESENT" : "ABSENT",
        unit: liveData.voltageInputStatus,
        isLabel: true
      },
      // {
      //   icon: pipelineTempIcon,
      //   name: t("pipeline_temperature"),
      //   value: getTempAsPerSelectedUnitSystem(
      //     Number(liveData.pipelineTemperature)
      //   ).temperature,
      //   unit: getTempAsPerSelectedUnitSystem(
      //     Number(liveData.pipelineTemperature)
      //   ).tempUnit,
      // },
    ],
    HPF: [
      {
        icon: volumeIcon,
        name: t("tank_volume"),
        value: getVolumeAsPerSelectedUnitSystem(Number(liveData.volume)).volume,
        unit: getVolumeAsPerSelectedUnitSystem(Number(liveData.volume))
          .volumeUnit,
      },
      {
        icon: thermometerIcon,
        name: t("tank_temperature"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.temperature))
          .temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.temperature))
          .tempUnit,
      },
      {
        icon: pipelineTempIcon,
        name: t("pipeline_temperature"),
        value: getTempAsPerSelectedUnitSystem(
          Number(liveData.pipelineTemperature)
        ).temperature,
        unit: getTempAsPerSelectedUnitSystem(
          Number(liveData.pipelineTemperature)
        ).tempUnit,
      },
    ],
    EVC: [
      {
        icon: subCoolTempIcon,
        name: t("subcool_temperature"),
        value: getTempAsPerSelectedUnitSystem(
          Number(liveData.subCoolTemp),
          true
        ).temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.subCoolTemp), true)
          .tempUnit,
      },
      {
        icon: hspIcon,
        name: t("HSP"),
        value: getPressureAsPerSelectedUnitSystem(Number(liveData.hspValue))
          .pressure,
        unit: getPressureAsPerSelectedUnitSystem(Number(liveData.hspValue))
          .pressureUnit,
      },
      {
        icon: CondensingIcon,
        name: t("sct"),
        value: getPressureAsPerSelectedUnitSystem(Number(liveData.condensingTemp))
          .pressure,
        unit: getPressureAsPerSelectedUnitSystem(Number(liveData.condensingTemp))
          .pressureUnit,
      },
      {
        icon: lspIcon,
        name: t("LSP"),
        value: getPressureAsPerSelectedUnitSystem(Number(liveData.lspValue))
          .pressure,
        unit: getPressureAsPerSelectedUnitSystem(Number(liveData.lspValue))
          .pressureUnit,
      },
      {
        icon: SaturatedSuctionIcon,
        name: t("sst"),
        value: getPressureAsPerSelectedUnitSystem(Number(liveData.saturatedSuctionTemp))
          .pressure,
        unit: getPressureAsPerSelectedUnitSystem(Number(liveData.saturatedSuctionTemp))
          .pressureUnit,
      },
      {
        icon: valveIcon,
        name: t("valve_position"),
        value: liveData.valvePos,
        unit: "",
      },
      {
        icon: fanSpeedIcon,
        name: t("fan_speed"),
        value: liveData.fanSpeed,
        unit: "",
      },
    ],
  };

  const toggleRegistrationCode = async (id, data) => {
    let type = "";
    let message = "";
    if(deviceType === "HPF") {
      if (data.HPFSerialNumber) {
        if (!data.registrationId) {
          // Generate Re-register Tank Button
          type = "GENERATE_REG_CODE";
          message = t("re_registers_successfully");
        } else {
          // Cancel Re-register Tank Button
          type = "DELETE_REG_CODE";
          message = t("cancel_registers_successfully");
        }
      }
    } else {
      if (data.deviceSerialNumber) {
        if (!data.deviceRegistrationId) {
          // Generate Re-register Tank Button
          type = "GENERATE_REG_CODE";
          message = t("re_registers_successfully");
        } else {
          // Cancel Re-register Tank Button
          type = "DELETE_REG_CODE";
          message = t("cancel_registers_successfully");
        }
      }
    }

    data.calibrationChart = data.calibrationChart ? data.calibrationChart.id : null
    const temp = {
      ...data,
      updateRegistrationCode: type,
      dairy: dairyID,
    };
    try {
      const res = deviceType === "HPF" ? await updateTank(id, temp) : await updateChiller(dairyID);
      if (dairyID === res.data.dairy.id) {
        const resObj = deviceType === "HPF" ? await getTanks(dairyID) : await getChillers(dairyID);
        setTanks(resObj.data);
        toast.dark(<Toaster icon="notify" message={message} />, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
    }
  };

  /** Show red indicator in device icon */
  useEffect(() => {
    if(deviceType === "HPF") {
      rootApi
        .get(
          `/alerthistories?where={"and":[{"alert":null},{"dairy":"${dairyID}"},{"tank":"${tankID}"},{"deviceType":{"!=":""}},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`
        )
        .then((response) => {
          let devices = [];
          response.data.map((item) => {
            if (item.tank && item.deviceID !== "") {
              if (!devices.includes(item.deviceID)) {
                devices.push(item.deviceID);
              }
            }
          });
          setUnResolvedDevices(devices);
        })
        .catch((err) => {
          console.error(err);
          toast.dark(
            <Toaster icon="error" message={t("live_alert_history_error")} />
          );
        });
    } else {
      rootApi
      .get(
        `/chilleralerthistories?where={"and":[{"chillerAlert":null},{"location":"${dairyID}"},{"device":"${tankID}"},{"deviceType":{"!=":""}},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`
      )
      .then((response) => {
        let devices = [];
        response.data.map((item) => {
          if (item.tank && item.deviceID !== "") {
            if (!devices.includes(item.deviceID)) {
              devices.push(item.deviceID);
            }
          }
        });
        setUnResolvedDevices(devices);
      })
      .catch((err) => {
        console.error(err);
        toast.dark(
          <Toaster icon="error" message={t("live_alert_history_error")} />
        );
      });
    }
  }, [tankID]);
  return (
    <>
      <Row className="row-full-width">
        <Col xl={2} lg={12} md={12} className="first-section">
          {tanksLoader && tankID !== undefined ? (
            <TanksLoader />
          ) : (
              <Row>
                <Col lg={12} md={12} sm={12} className="px-2">
                  {["SuperAdmin", "DairyOperator", "DairyAdmin"].indexOf(
                    user.role
                  ) != -1 ? (
                      <DragDropContext
                        onDragEnd={onDragEnd}
                        onBeforeDragStart={onBeforeDragStart}
                      >
                        <Droppable droppableId="tanks-droppable">
                          {(dropProvided) => {
                            return (
                              <div
                                ref={dropProvided.innerRef}
                                {...dropProvided.droppableProps}
                              >
                                {tanks.map((tank, index) => {
                                  const { location, history } = props;
                                  const isAlert = tankAlert.includes(tank.id);
                                  const deviceType = localStorage.getItem("deviceType");
                                  return (
                                    <Draggable
                                      draggableId={tank.id}
                                      index={index}
                                      key={tank.id}
                                    >
                                      {(dragProvided) => (
                                        <div
                                          {...dragProvided.draggableProps}
                                          {...dragProvided.dragHandleProps}
                                          ref={dragProvided.innerRef}
                                        >
                                          <TankCard
                                            key={"tank" + index}
                                            deviceType={deviceType}
                                            click={() => {
                                              let url = location.pathname.split(
                                                "/"
                                              );
                                              url.pop();
                                              url.push(tank.id);
                                              url = url.join("/");
                                              history.replace(url);
                                              setTankSettings(false);
                                              setLiveAlerts(false);
                                              showRawRegisterValues(false);
                                              showRegisterValues(false);
                                              if(deviceType === "HPF"){
                                                setDeviceType("HPF")
                                                setDeviceId(deviceIdObj?.HPF[0]);
                                              }else{
                                                setDeviceType("CHILLER")
                                                setDeviceId(deviceIdObj?.CHILLER[0]);
                                              }
                                            }}
                                            remove={() => {
                                              setTankDeleteAlert(true);
                                              setTankDeleteIndex(index);
                                            }}
                                            editTank={() => {
                                              setIsTankEdit(true);
                                              setCurrentEditTank({ ...tank });
                                              setShowAddTank(true);
                                            }}
                                            tank={tank}
                                            isactive={tankID === tank.id}
                                            toggleRegistrationCode={() =>
                                              toggleRegistrationCode(tank.id, tank)
                                            }
                                            isAlert={isAlert}
                                            setRawRegisterValue={setRawRegisterValue}
                                            setRegisterValuesFlag={setRegisterValuesFlag}
                                            setTankSettings={setTankSettings}
                                            setLiveAlerts={setLiveAlerts}
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })}
                              </div>
                            );
                          }}
                        </Droppable>
                      </DragDropContext>
                    ) : (
                      <div>
                        <div id="tanks-droppable">
                          <div>
                            {tanks.map((tank, index) => {
                              const { location, history } = props;
                              const isAlert = tankAlert.includes(tank.id);
                              const deviceType = localStorage.getItem("deviceType");
                              return (
                                <div>
                                  <TankCard
                                    key={"tank" + index}
                                    deviceType={deviceType}
                                    click={() => {
                                      let url = location.pathname.split("/");
                                      url.pop();
                                      url.push(tank.id);
                                      url = url.join("/");
                                      history.replace(url);
                                      setLiveAlerts(false);
                                      showRawRegisterValues(false);
                                      showRegisterValues(false);
                                      setTankSettings(false);
                                      if(deviceType === "HPF"){
                                        setDeviceType("HPF")
                                        setDeviceId(deviceIdObj?.HPF[0]);
                                      }else{
                                        setDeviceType("CHILLER")
                                        setDeviceId(deviceIdObj?.CHILLER[0]);
                                      }
                                    }}
                                    remove={() => {
                                      setTankDeleteAlert(true);
                                      setTankDeleteIndex(index);
                                    }}
                                    editTank={() => {
                                      setIsTankEdit(true);
                                      setCurrentEditTank({ ...tank });
                                      setShowAddTank(true);
                                    }}
                                    tank={tank}
                                    isactive={tankID === tank.id}
                                    toggleRegistrationCode={() =>
                                      toggleRegistrationCode(tank.id, tank)
                                    }
                                    isAlert={isAlert}
                                    setRawRegisterValue={setRawRegisterValue}
                                    setRegisterValuesFlag={setRegisterValuesFlag}
                                    setTankSettings={setTankSettings}
                                    setLiveAlerts={setLiveAlerts}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                </Col>
                {!drag && tankID !== undefined && (
                  <Col lg={12} className="text-center">
                    {user.role === "MilkHauler" || user.role === "Sanitarian" ? (
                      ""
                    ) : (
                        <div
                          className="add-tank"
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsTankEdit(false);
                            setShowAddTank(true);
                          }}
                        >
                          <img src={addTankIcon} alt="" />
                          <span className="ml-3">{deviceType === "HPF" ? t("add_new_tank") : t("add_device")}</span>
                        </div>
                      )}
                  </Col>
                )}
              </Row>
            )}
        </Col>
        <Col xl={8} lg={12} md={12} className="second-section">
          {tankID ? (
            // isTankRegistered && dairyAdmin ? (
            isTankRegistered ? (
              <>
                <div className="tank-device d-flex">
                  {deviceIdObj &&
                    (deviceType === "HPF" ? deviceIdObj?.HPF[0] : deviceIdObj?.CHILLER[0]) !== null &&
                    deviceIdObj?.EVC.length !== null &&
                    (isShowingRawRegisterValues === false && isShowingRegisterValues === false) && (
                      <GraphTypeWrapper
                        className={
                          deviceIdObj
                            ? deviceIdObj.EVC.length + (deviceType === "HPF" ? deviceIdObj.HPF.length : deviceIdObj.CHILLER.length) >
                              5
                              ? "border-right device-scroll"
                              : "border-right"
                            : 0
                        }
                      >
                        <TankDevices
                          currentTankData={currentTankData}
                          tankType={tankType}
                          setDeviceId={setDeviceId}
                          deviceType={deviceType}
                          deviceId={deviceId}
                          setLiveAlerts={setLiveAlerts}
                          setTankSettings={setTankSettings}
                          setDeviceType={setDeviceType}
                          deviceIdObj={deviceIdObj}
                        />
                      </GraphTypeWrapper>
                    )}
                  {
                    (isShowingRawRegisterValues || isShowingRegisterValues) && (
                      <div className="row-register-back-arrow d-flex flex-start" >
                        <BackArrow onClick={() => {
                          showRawRegisterValues(false);
                          showRegisterValues(false)
                        }} />
                      </div>
                    )}
                  <GraphControlDiv>
                    {
                      (isShowingRawRegisterValues === false && isShowingRegisterValues === false) && (

                        <Button
                          className={`${liveAlerts === true
                            ? deviceIdObj === null
                              ? "mr-auto px-2 ml-0 alert-btn-active"
                              : "mr-auto ml-3 px-2 alert-btn-active"
                            : deviceIdObj === null
                              ? "mr-auto px-2 ml-0 alert-btn"
                              : "mr-auto ml-3 px-2 alert-btn"
                            }`}
                          variant="none"
                          onClick={() => {
                            setLiveAlerts(!liveAlerts);
                            setDeviceId("");
                            showRawRegisterValues(false);
                            setTankSettings(false);
                          }}
                        >
                          <span
                            className={`${tankAlert.includes(tankID)
                              ? "alert-red-indicator position-relative"
                              : "text-primary"
                              }`}
                          >
                            <Image src={AlertIcon} />
                          </span>
                        </Button>
                      )
                    }


                    <div
                      className={
                        showAnnotation === true
                          ? "annotation bg-light-blue"
                          : "annotation white"
                      }
                      show={showAnnotation}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (showAnnotation) {
                          setShowAnnotation(false);
                          setManualAnnotation(false);
                        } else {
                          setShowAnnotation(true);
                        }
                      }}
                    >
                      {showAnnotation ? (
                        <div className="d-flex align-items-center">
                          <div className="hide-annotation">
                            {t("hide_annotation")}
                          </div>
                          <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={
                              <Tooltip
                                id="tooltip-disabled"
                                className="annotation-tooltip"
                              >
                                {t("add_annotation")}
                              </Tooltip>
                            }
                          >
                            <span
                              className="ml-1"
                              show={manualAnnotation}
                              onClick={(e) => {
                                e.stopPropagation();
                                setManualAnnotation(!manualAnnotation);
                              }}
                            >
                              <Image src={plusIcon} />
                            </span>
                          </OverlayTrigger>
                        </div>
                      ) : (
                          t("show_annotation")
                        )}
                    </div>

                    <div className="pause">
                      <Dropdown pause handleClick={setAutoReloadInterval} />
                    </div>
                    <div
                      className="reload-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setCustomDates([
                          new Date(ranges.Yesterday[0]).toISOString(),
                          new Date().toISOString(),
                        ]);
                        setReloadCount(reloadCount + 1);
                      }}
                    >
                      <Image src={reloadIcon} alt="" />
                    </div>
                    <div>
                      <DateRangePicker
                        initialSettings={{
                          autoUpdateInput: false,
                          timePicker: true,
                          startDate: new Date(),
                          endDate: new Date(),
                          ranges,
                          opens: "left",
                        }}
                        onApply={() => {
                          getGraphData();
                          getAlerts();
                        }}
                        onCallback={handleDateCallback}
                      >
                        <div className="text-center date-button">
                          <span>
                            {`${moment(customDates[0]).format(
                              "DD MMM"
                            )} - ${moment(customDates[1]).format("DD MMM")}`}
                          </span>
                          <Image
                            src={downCaret}
                            alt=""
                            style={{ marginLeft: "10px" }}
                          />
                        </div>
                      </DateRangePicker>
                    </div>
                    <div
                      className="text-center reload-button"
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        setShow(true);
                        setCurrentEditTank(() =>
                          tanks.find((i) => i.id === tankID)
                        );
                      }}
                    >
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                          <Tooltip
                            id="tooltip-disabled"
                            className="annotation-tooltip mt-2"
                          >
                            {t("export_csv")}
                          </Tooltip>
                        }
                      >
                        <Image src={IconDownLoad} fluid />
                      </OverlayTrigger>
                    </div>
                    {/* <div
                      className={
                        isShowingRegisterValues === true
                          ? "annotation bg-light-blue"
                          : "annotation white"
                      }
                      show={isShowingRegisterValues}
                      onClick={() => {
                        showRegisterValues(!isShowingRegisterValues);
                        setDeviceId("");
                        setLiveAlerts(false);
                      }}
                    >
                      {t("register values")}
                    </div> */}
                  </GraphControlDiv>
                </div>

                {tankSettings === true && liveAlerts === false && (
                  <Row>
                    <Col lg={12}>
                      <Settings />
                    </Col>
                  </Row>
                )}

                {liveAlerts === true && tankSettings === false && (
                  <Row>
                    <Col lg={12}>
                      <LiveAlertsNotifications
                        currentTankData={currentTankData}
                        dropdownTime={props.dropdownTime}
                      />
                    </Col>
                  </Row>
                )}

                {(isShowingRawRegisterValues === true && isShowingRawRegisterValuesLoading === true)
                  ? (
                    <div className="my-4 rounded px-4 py-4 live-alerts h-74">
                      <RawRegisterLoader />
                    </div>
                  ) : (isShowingRawRegisterValues === true && isShowingRawRegisterValuesLoading === false) ? (
                    <div className="my-4 rounded px-4 py-4 live-alerts h-74">
                      <div class="d-flex flex-wrap m-auto">
                        {
                          Object.keys(rawRegisters).map((register) => (
                            <div className="raw-rawRegister mb-3">
                              <div className="d-flex flex-column"><p>{register}</p></div>
                              <div className="col-rawRegister"><p>{rawRegisters[register]}</p></div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : ("")}
                {isShowingRegisterValues === true && (
                  <div className="my-4 rounded px-4 py-4 live-alerts">
                    <div class="m-auto">{
                      !isLoadingRegisterValues ? (
                        <Tabs

                          className="main-tab register-values-tabs"
                        >
                          {

                            registerValues
                              ? Object.keys(registerValues).map((deviceType, index) => {
                                let imgTankDevice;
                                if (deviceType.includes('HPF') || deviceType.includes('CHILLER')) {
                                  imgTankDevice = registerValues.tankTypeName === 'Vertical'
                                    ? <img src={tankVerticalIcon} className="register-tank-vertical" alt="tank icon" />
                                    : <img src={horizontalTank} className="register-tank-vertical" alt="tank icon" />;
                                } else {
                                  imgTankDevice = <img src={EVC} className="evc-tank-icon" alt="evc icon" />;
                                }
                                return (
                                  <Tab eventKey={index} title={imgTankDevice} key={deviceType}>
                                    {
                                      Object.keys(registerValues[deviceType]).map((deviceId) => (
                                        <div key={deviceId} className="tabcontentBlock">
                                          <strong>
                                            {' '}
                               ID :
                              {' '}
                                            {deviceId}
                                            {' '}
                                          </strong>
                                          <table style={{ border: "1px solid #adcdff", marginTop: "10px", width: "50%" }}>
                                            {
                                              Object.keys(registerValues[deviceType][deviceId])
                                                .map((deviceRegisters) => {
                                                  for (let i = 0; i < config.length; i += 1) {
                                                    if (`${deviceRegisters}_ADDR` === config[i].key || deviceRegisters === config[i].key || deviceRegisters === `${deviceType}_INPUT_REG_MAJOR_REVISION` || deviceRegisters === 'EVC_IDENTIFICATION') {
                                                      let sensorValue = '';
                                                      let sensorUnit = '';
                                                      let deviceWithId = registerValues[deviceType][deviceId];
                                                      if (config[i].key.includes('TEMP')) {
                                                        const {
                                                          temperature,
                                                          tempUnit,
                                                        } = getTempAsPerSelectedUnitSystem(Number(registerValues[deviceType][deviceId][deviceRegisters]), config[i].key.includes('SUBCOOL'));
                                                        sensorUnit = tempUnit;
                                                        sensorValue = temperature;
                                                      } else if (config[i].key === 'HPF_INPUT_REG_VOLUME_4TO20MA_READING') {
                                                        const {
                                                          volume,
                                                          volumeUnit,
                                                        } = getVolumeAsPerSelectedUnitSystem(Number(registerValues[deviceType][deviceId][deviceRegisters]));
                                                        sensorUnit = volumeUnit;
                                                        sensorValue = volume;
                                                      } else if (config[i].key.includes('PRESSURE')) {
                                                        const {
                                                          pressure,
                                                          pressureUnit,
                                                        } = getPressureAsPerSelectedUnitSystem(Number(registerValues[deviceType][deviceId][deviceRegisters]));
                                                        sensorUnit = pressureUnit;
                                                        sensorValue = pressure;
                                                      } else {
                                                        sensorUnit = config[i].unit;
                                                        sensorValue = deviceWithId[deviceRegisters];
                                                      }
                                                      let tableRowToReturn = (
                                                        <tbody className="row-register-table" key={deviceRegisters}>
                                                          <td>{config[i].displayName}</td>
                                                          <td>
                                                            {sensorValue}
                                                            {' '}
                                                            {sensorUnit}
                                                          </td>
                                                        </tbody>
                                                      );
                                                      if (deviceRegisters === 'EVC_IDENTIFICATION') {
                                                        switch (deviceWithId[deviceRegisters]) {
                                                          case '32':
                                                            sensorValue = ' Bottom Unit #1';
                                                            break;
                                                          case '33':
                                                            sensorValue = ' Bottom Unit #2';
                                                            break;
                                                          case '34':
                                                            sensorValue = ' Side Unit #3';
                                                            break;
                                                          case '35':
                                                            sensorValue = ' Side Unit #4';
                                                            break;
                                                          default:
                                                            sensorValue = '-';
                                                        }
                                                        tableRowToReturn = (
                                                          <tbody className="row-register-table" key={deviceRegisters} >
                                                            <td>
                                                              {deviceType} Number
                                                      </td>
                                                            <td>
                                                              {sensorValue}
                                                            </td>
                                                          </tbody>
                                                        );
                                                      } else if (deviceRegisters === `${deviceType}_INPUT_REG_MAJOR_REVISION`) {
                                                        tableRowToReturn = (
                                                          <tbody className="row-register-table" key={deviceRegisters} >
                                                            <td>{deviceType} Firmware Version</td>
                                                            <td>
                                                              {deviceWithId[`${deviceType}_INPUT_REG_MAJOR_REVISION`] + '.' + deviceWithId[`${deviceType}_INPUT_REG_MINOR_REVISION`] + '.' + deviceWithId[`${deviceType}_INPUT_REG_BUILD_NUMBER`] + '.' + deviceWithId[`${deviceType}_INPUT_REG_HW_REVISION`]}
                                                            </td>
                                                          </tbody>
                                                        )
                                                      }
                                                      return tableRowToReturn;
                                                    }
                                                  }
                                                  return '';
                                                })
                                            }
                                          </table>
                                        </div>
                                      ))
                                    }
                                  </Tab>
                                );
                              }).reverse()
                              : (
                                <center>
                                  <NoDataAvailable title={t("no_data")} />
                                </center>
                              )
                          }
                        </Tabs>
                      ) : (<ParseRegisterLoader />)}
                    </div>
                  </div>
                )}
                {manualAnnotation && (
                  <Alert
                    className="mt-5"
                    variant="warning"
                    onClose={() => setManualAnnotation(!manualAnnotation)}
                    dismissible
                  >
                    {t("add_annotation_instruction")}
                  </Alert>
                )}

                {isShowingRawRegisterValues !== true && isShowingRegisterValues !== true && liveAlerts !== true && tankSettings !== true && (
                  <>
                    <Chiller
                      graphLoader={graphLoader}
                      deviceType={deviceType}
                      liveAlerts={liveAlerts}
                      chillerGraphData={chillerGraphData}
                      sensors={sensors}
                      showAnnotation={showAnnotation}
                      washData={washData}
                      alerts={alerts}
                      manualAnnotation={manualAnnotation}
                      getAlerts={getAlerts}
                      configId={configId}
                      dropdownTime={props.dropdownTime}
                    />

                    <Hpf
                      graphLoader={graphLoader}
                      deviceType={deviceType}
                      liveAlerts={liveAlerts}
                      hpfGraphData={hpfGraphData}
                      sensors={sensors}
                      showAnnotation={showAnnotation}
                      washData={washData}
                      alerts={alerts}
                      manualAnnotation={manualAnnotation}
                      getAlerts={getAlerts}
                      configId={configId}
                      dropdownTime={props.dropdownTime}
                    />

                    <Evc
                      graphLoader={graphLoader}
                      deviceType={deviceType}
                      liveAlerts={liveAlerts}
                      evcGraphData={evcGraphData}
                      sensors={sensors}
                      showAnnotation={showAnnotation}
                      washData={washData}
                      alerts={alerts}
                      manualAnnotation={manualAnnotation}
                      getAlerts={getAlerts}
                      configId={configId}
                      dropdownTime={props.dropdownTime}
                    />
                  </>
                )}
              </>
            ) : (
                <center>
                  { !dairyAdmin ? <NoDataAvailable title={t("the_dairy_owner_has_not_registered")} />
                  : <NoDataAvailable title={t("tank_not_registered")} /> }
                </center>
              )
          ) : (
              <center>
                <NoTankDataAvailable
                  setIsTankEdit={setIsTankEdit}
                  setShowAddTank={setShowAddTank}
                />
              </center>
            )}
        </Col>
        {
          tankID === undefined ? (
            ""
          ) : (
              <Col xl={2} lg={12} md={12} className="third-section">
                <LiveDataSideBar
                  tankStatusObj={tankStatusObj}
                  agitatedTime={agitatedTime}
                  liveDataHelper={liveDataHelper}
                  deviceType={deviceType}
                  secondsToHourMin={secondsToHourMin}
                  currentTankData={currentTankData}
                  dropdownTime={props.dropdownTime}
                />
              </Col>
            )
        }
      </Row >
      { deviceType === "HPF" ? 
        <AddTankForm
          dealerID={dealerID}
          show={showAddTank}
          close={() => setShowAddTank(false)}
          dairy={dairyName}
          addNewTank={addNewTank}
          edit={isTankEdit}
          tank={currentEditTank}
          editTank={editTank}
        />  :       
        <AddDeviceControlForm
          dealerID={dealerID}
          show={showAddTank}
          close={() => setShowAddTank(false)}
          dairy={dairyName}
          addNewTank={addNewTank}
          edit={isTankEdit}
          tank={currentEditTank}
          editTank={editTank}
        />
      }
      <PromptModel
        open={tankDeleteAlert}
        closeAlert={() => {
          setTankDeleteAlert(false);
        }}
        modelContent={t("are_you_sure")}
        callback={() => {
          if (tankDeleteIndex !== null) {
            removeTank(tankDeleteIndex);
          }
        }}
      />
      {
        show && (
          <ExportCsvForm
            showModel={show}
            closeModel={() => setShow(false)}
            tank={currentEditTank}
            exportDeviceIds={exportDeviceIds}
            dropdownTime={props.dropdownTime}
          />
        )
      }
    </>
  );
});
TankListing.propTypes = {
  location: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  dropdownTime: PropTypes.any,
};
export default withRouter(TankListing);

import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import Graph from "../../components/Graph";
import GraphLoader from "../../components/GraphLoader";
import NoDataAvailable from "../../components/NoDataAvailable";

const EVC = memo(
  ({
    graphLoader,
    deviceType,
    liveAlerts,
    evcGraphData,
    sensors,
    washData,
    showAnnotation,
    alerts,
    manualAnnotation,
    getAlerts,
    configId,
    dropdownTime,
    isResponsiveMode,
  }) => {
    const { t } = useTranslation();
    return (
      <>
        {deviceType === "EVC" && liveAlerts === false
          ? evcGraphData.map((data, index) => {
              if (graphLoader) {
                return <GraphLoader key={"evc" + index} />;
              }
              if (data) {
                return (
                  <Row className="">
                    <Col lg={12} key={`evcGraph ${index}`}>
                      <Graph
                        data={data}
                        sensors={sensors.EVC[index]}
                        graphId={`evc${index}`}
                        showAnnotation={showAnnotation}
                        alerts={alerts}
                        washData={washData}
                        manualAnnotation={manualAnnotation}
                        getAlerts={getAlerts}
                        configId={configId}
                        dropdownTime={dropdownTime}
                        isResponsiveMode={isResponsiveMode}
                        deviceType={deviceType}
                      />
                    </Col>
                  </Row>
                );
              }
              return (
                <center key={index}>
                  <NoDataAvailable title={t("no_data")} />
                </center>
              );
            })
          : null}
      </>
    );
  }
);

export default EVC;

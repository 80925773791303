import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Redirect } from "react-router-dom";
import {
  Col,
  Row,
  Container,
  Form,
  Button,
  Image,
  Spinner,
} from "react-bootstrap";
import * as yup from "yup";
import { login, getUser } from "../actions/actions";
import MuellerLogo from "../assets/images/Mueller_dark_logo.svg";
import MilkSecureLogo from "../assets/images/MilkSecure_Logo_Two_Color.svg";
import MyMuellerLogo from "../assets/images/myMuellerLogo.svg";
import { useAuthContext } from "../context/auth";
import { Link } from "react-router-dom";
import { PMCUrl, PMCClientId, DairyAlertUrl } from "../config";
import EyeIcon from "../assets/images/BlueEyesIcon.svg";
import CloseEyeIcon from "../assets/images/close-eye-icon.svg";
import PrivacyPolicyLinks from "../components/PrivacyPolicyLinks";
import LanguageDropdown from "../components/LanguageDropdown";
import Slider from "../components/Slider";
import jwt from "jsonwebtoken";

const Login_PMC_URL = `${PMCUrl}?response_type=code&client_id=${PMCClientId}&redirect_uri=${DairyAlertUrl}/callback&scope=read`;

const schema = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required(),
});

const LoginPage = memo(() => {
  const [spinner, setSpinner] = useState(false);
  const [password, setPassword] = useState(false);
  const { authUser, setAuthUser } = useAuthContext();
  const { t, i18n } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    validationSchema: schema,
  });
  const [errorMessage, setErrorMessge] = useState();

  // If user is logging in through OAuth, then get the JWT token from the query param and save it

  var urlParams = new URLSearchParams(window.location.search);

  if (urlParams && urlParams.get("jwt") !== null) {
    var data = jwt.decode(urlParams.get("jwt"));
    localStorage.setItem("token", urlParams.get("jwt"));
    data.expire = new Date();
    localStorage.setItem("user", JSON.stringify(data));
    window.location.href = "/";
  }

  const postLogin = ({ email, password }) => {
    setSpinner(true);
    login(email, password)
      .then((result) => {
        setSpinner(false);
        if (result.status === 200) {
          setAuthUser(result.data.user, result.data.token);
        }
        const { languagePreference } = getUser();
        i18n.changeLanguage(languagePreference.replace(" ", ""));
        localStorage.setItem("i18nextLng", languagePreference.replace(" ", ""));
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setErrorMessge(
          err && err.response
            ? err.response.data.message
            : t("unable_to_connect_server")
        );
      });
  };

  // Redirect to referrer link or "/"" if already logged in
  // TODO: Implement redirect to referer link after login
  // const referer = props.location.state.referer || "/";
  const referer = "/";
  if (authUser.token) {
    return <Redirect to={referer} />;
  }

  return (
    <div className="bg-login-light">
      <Container fluid="xl" fluid className="min-vh-100">
        <Row className="min-vh-100">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            xl={6}
            className="d-none d-sm-none d-md-none bg-dark-secondary align-items-start d-lg-flex justify-content-lg-start justify-content-xl-center"
          >
            <Slider />
          </Col>
          <Col
            className="m-xl-auto mx-lg-auto"
            xs={{ span: 10, offset: 1 }}
            sm={{ span: 8, offset: 2 }}
            md={{ span: 8, offset: 2 }}
            lg={{ span: 4, offset: 2 }}
            xl={{ span: 4, offset: 2 }}
          >
            <div className="text-center my-lg-4 my-md-5 logo-top">
              <Image src={MuellerLogo} fluid className="mueller-logo" />
            </div>
            <div className="shadow bg-white px-lg-5 px-md-5 px-4 py-1 mt-xl-5 mt-3">
              <p className="text-center pt-4 pb-3 text-dark lead">
                {t("login_screen_text")}
              </p>
              <Form className="mb-5" onSubmit={handleSubmit(postLogin)}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    name="email"
                    size="lg"
                    type="email"
                    placeholder={t("enter_email")}
                    ref={register()}
                    isInvalid={!!errors.email}
                  />
                  {errors.email && (
                    <Form.Control.Feedback type="invalid">
                      {t("required_field_error")}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Control
                    name="password"
                    size="lg"
                    type={password === true ? "text" : "password"}
                    placeholder={t("password")}
                    ref={register()}
                    isInvalid={!!errors.password}
                  />
                  <Button
                    variant="link"
                    className="eye-icon"
                    aria-label="Eye Icon"
                    onClick={() => setPassword(!password)}
                  >
                    {password === false ? (
                      <Image src={EyeIcon} />
                    ) : (
                        <Image src={CloseEyeIcon} />
                      )}
                  </Button>
                  {errors.password && (
                    <Form.Control.Feedback type="invalid">
                      {t("required_field_error")}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Button
                  className="mt-4 text-capitalize"
                  variant="primary"
                  size="lg"
                  type="submit"
                  block
                >
                  {spinner ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="md"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Loading...</span>
                    </>
                  ) : (
                      t("login")
                    )}
                </Button>
                <div className="text-center mt-3 mb-2">
                  <div className="mb-2">
                    <Link to="/forgot-password">
                      <u>{t("forgot_password")}</u>
                    </Link>
                  </div>
                  <small>{t("or")}</small>
                </div>
                <Button
                  className="font-weight-medium back-arrow-hover border-dark login-dark-text flex-center"
                  variant="outline-none"
                  size="lg"
                  type="button"
                  block
                  onClick={() => {
                    window.location.href = Login_PMC_URL;
                  }}
                >
                  {t("PMC_Login")}
                  <Image src={MyMuellerLogo} className="ml-1" />
                </Button>
              </Form>
            </div>

            {errorMessage ? (
              <div className="text-danger mt-3 text-center">{errorMessage}</div>
            ) : (
                ""
              )}
            <div className="text-center mt-4 mb-3">
              <p className="text-capitalize mb-1">{t("powered_by")}</p>
              <Image src={MilkSecureLogo} />
            </div>
            <div className="mt-3 text-center">
              <PrivacyPolicyLinks />
            </div>
            <div className="mt-0 text-center">
              <LanguageDropdown />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
});

export default LoginPage;

import _ from "lodash";
import styled from "styled-components";
import React, { memo, useState } from "react";
import { Image } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import tankVerticalIcon from "../../assets/images/vertical-tank.svg";
import TankHPF from "../../assets/images/Tank002.png";
import TankCHILLER from "../../assets/images/Bakery-Chiller-1.png";
import EVC_GIF_ICON from "../../assets/images/EVC-fan-gif.gif";
import EVC_ICON from '../../assets/images/EVC-fan.png';
import { registerNames, getFlexWidth } from "../../actions/passwordRegex";
import {
  getTempAsPerSelectedUnitSystem,
  getVolumeAsPerSelectedUnitSystem,
  getPressureAsPerSelectedUnitSystem,
} from "../../helpers";

const GraphTypeSubWrapper = styled.div`
  padding: 10px;
  margin-right: 10px;
  border-bottom: ${({ active }) => (active ? "3px solid #2c61a5" : "none")};
  background-color: ${({ active }) => (active ? "#f0f6ff" : "white")};
  display: inline-block;
`;

const GraphTypeWrapper = styled.div`
  // overflow: ${({ numDevices }) => (numDevices >= 7 ? "scroll" : "initial")};
  // white-space: ${({ numDevices }) => (numDevices >= 7 ? "nowrap" : "initial")};
  // flex: ${({ numDevices }) => `0 0 ${getFlexWidth(numDevices)}%`};
  // img {
  //   position: relative;
  //   width: 35px;
  //   cursor: pointer;
  // }
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DeviceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor:pointer
`;

const TankDevices = memo(
  ({
    currentTankData,
    tankType,
    setDeviceId,
    deviceType,
    deviceId,
    setLiveAlerts,
    setTankSettings,
    setDeviceType,
    deviceIdObj,
  }) => {
    const [hoveredData, setHoveredData] = useState("");
    const [indicator, setIndicator] = useState("");
    let configs = JSON.parse(localStorage.getItem("configs"));
    const { t } = useTranslation();

    configs = configs.filter((config, index, self) => {
      return (index === self.findIndex(i => i.key === config.key));
    });

    const tooltipData = (hoveredDeviceType, hoveredDeviceId) => {
      if (
        currentTankData &&
        currentTankData[hoveredDeviceType] &&
        currentTankData[hoveredDeviceType][hoveredDeviceId]
      ) {
        if (hoveredDeviceType === "CHILLER") {
          hoveredDeviceType = "CHILLER";
          hoveredDeviceId = Object.keys(currentTankData[hoveredDeviceType])[0];
        }
        setHoveredData(currentTankData[hoveredDeviceType][hoveredDeviceId]);
        let indicatorItem = currentTankData[hoveredDeviceType][hoveredDeviceId]
          .EVC_IDENTIFICATION
          ? currentTankData[hoveredDeviceType][hoveredDeviceId]
            .EVC_IDENTIFICATION
          : "";
        setIndicator(indicatorItem);
      }
      else {
        setHoveredData('');
      }
    };

    const tempBarData = [
      "HPF_INPUT_REG_TEMP1",
      "HPF_INPUT_REG_TEMP2",
      "HPF_INPUT_REG_VOLUME_4TO20MA_READING",
      "EVC_INPUT_REG_SUBCOOL_TEMP",
      "EVC_INPUT_REG_HIGH_SIDE_PRESSURE",
      "EVC_INPUT_REG_LOW_SIDE_PRESSURE",
    ];

    return (
      <GraphTypeWrapper
        className="border-right"
        numDevices={
          deviceIdObj ? deviceIdObj.EVC.length + (deviceType === "HPF" ? deviceIdObj.HPF.length : deviceIdObj.CHILLER.length) : 0
        }
      >
        {deviceIdObj &&
          Object.keys(deviceIdObj).map((key) => {
            return deviceIdObj[key].map((id, index) => {
              // const evcRotateSignal = Number.parseInt(currentTankData?.EVC?.[id]?.RUN_SIGNAL) === 0;
              const evcRotateSignal = Number.parseInt(currentTankData?.EVC?.[id]?.RUN_SIGNAL) !== 0;
              return (
                <span onMouseOver={(event) => tooltipData(key, id, event)}>
                  <GraphTypeSubWrapper active={deviceId === id} key={id}>
                    {tankType === null ? (
                      <div
                        className="active offline offlineTank pt-1 pb-1"
                        onClick={() => {
                          if (deviceType === key && deviceId === id) {
                            return;
                          }
                          setDeviceType(`${key}`);
                          setDeviceId(`${id}`);
                          setTankSettings(false);
                          setLiveAlerts(false);
                        }}
                      >
                        <span
                          className={
                            key === "HPF" || key === "CHILLER" ? "single-tank-page" : "evc-img"
                          }
                          at-data={id}
                        >
                          --
                        </span>
                      </div>
                    ) : (
                      <DeviceContainer                             
                        onClick={() => {
                          if (deviceType === key && deviceId === id) {
                            return;
                          }
                          setDeviceType(`${key}`);
                          setDeviceId(`${id}`);
                          setTankSettings(false);
                          setLiveAlerts(false);
                        }}>
                          { key === "EVC" ? <span>{`Stage ${index + 1}`}</span> : null }
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                              hoveredData
                              ? <Tooltip
                                  id={`tooltip-${id}`}
                                  className="device-tool-tip"
                                >
                                  {hoveredData !== "" &&
                                    _.invert(registerNames)[indicator] !== undefined ? (
                                    <span
                                      className="diffTempValues bottom-unit"
                                      key={key}
                                    >
                                      {_.invert(registerNames)[indicator]}
                                      <br />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {hoveredData !== "" || hoveredData !== undefined
                                    ? configs.map((configKey) =>
                                      Object.keys(hoveredData).map((temp) => {
                                        if (configKey.key === temp) {
                                          let sensorValue = hoveredData[temp];
                                          let sensorUnit = configKey.unit;

                                          if (configKey.key.includes("TEMP")) {
                                            const {
                                              temperature,
                                              tempUnit,
                                            } = getTempAsPerSelectedUnitSystem(Number(hoveredData[temp]), configKey.key.includes("SUBCOOL"));

                                            sensorValue = temperature;
                                            sensorUnit = tempUnit;
                                          } else if (configKey.key === "HPF_INPUT_REG_VOLUME_4TO20MA_READING") {
                                            const {
                                              volume,
                                              volumeUnit,
                                            } = getVolumeAsPerSelectedUnitSystem(Number(hoveredData[temp]));

                                            sensorUnit = volumeUnit;
                                            sensorValue = volume;
                                          } else if (configKey.key.includes("PRESSURE")) {
                                            const {
                                              pressure,
                                              pressureUnit,
                                            } = getPressureAsPerSelectedUnitSystem(Number(hoveredData[temp]));

                                            sensorUnit = pressureUnit;
                                            sensorValue = pressure;
                                          }
                                          return (

                                            <span>
                                              {tempBarData.indexOf(temp) > -1 ? (
                                                <span
                                                  className="diffTempValues"
                                                  key={temp}
                                                >
                                                  { configKey.displayName }:
                                                  <span className="hoveredNum">
                                                    {` ${sensorValue} `}
                                                  </span>
                                                  {" "}{sensorUnit}
                                                  <br />
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </span>
                                          );
                                        }
                                        return "";
                                      })
                                    )
                                    : ""}
                                </Tooltip>
                              : <Tooltip>{t('no_data')}</Tooltip>
                              }
                            >
                            <Image
                              className={
                                key === "HPF" || key === "CHILLER"
                                  ? tankType == "1"
                                    ? "vertical-image"
                                    : "hpf-image"
                                  : "evc-image"
                              }
                              src={
                                key === "HPF" || key === "CHILLER"
                                  ? tankType == "1"
                                    ? tankVerticalIcon
                                    : key === "HPF" ? TankHPF : TankCHILLER
                                  : evcRotateSignal
                                    ? EVC_GIF_ICON
                                    : EVC_ICON
                              }
                            />
                          </OverlayTrigger>
                          { key === "EVC" ? <span>{`Stage ${index + 1}`}</span> : null }
                      </DeviceContainer>
                    )}
                  </GraphTypeSubWrapper>
                </span>
              );
            });
          })}
      </GraphTypeWrapper>
    );
  }
);

export default TankDevices;

import React, { memo, useEffect, useState, useCallback, useRef } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";
import { useParams, withRouter } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import TankCard from "../../components/TankCard";
import Tank from "../../assets/images/Tank002.png";
import Settings from "../../containers/Tank/Settings";
import TankHPF from "../../assets/images/Tank002.png";
import TankCHILLER from "../../assets/images/Bakery-Chiller-1.png";
import EVC_GIF_ICON from "../../assets/images/EVC-fan-gif.gif";
import EVC_ICON from '../../assets/images/EVC-fan.png';
import TankTwo from "../../assets/images/EVC-fan-gif.gif";
import io from "../../actions/socket";
import TanksLoader from "../../components/TankLoader";
import GraphLoader from "../../components/GraphLoader";
import LiveDataSideBar from "../../components/LiveDataContent";
import _ from "lodash";
import { useMediaQuery } from "react-responsive";
import { Drawer } from "antd";

import {
  getTanks,
  getChillers,
  getTankHPFandEVCId,
  getTankCHILLERandEVCId,
  getLastAgitatedValue,
  fetchGraph,
  fetchGraphChiller,
  fetchDairy,
  addTank,
  addNewChillerController,
  deleteTank,
  deleteChiller,
  updateTank,
  updateChiller,
  getTankById,
  getChillerById,
  getUser,
  getTankAlerts,
  getChillerAlerts,
  fetchConfigs,
} from "../../actions/actions";
import addTankIcon from "../../assets/images/white-plus-icon.svg";
import downCaret from "../../assets/images/date-box-caret.svg";
import AddTankForm from "../../forms/TankForm/AddTankForm";
import AddDeviceControlForm from "../../forms/DeviceControlForm/AddDeviceControlForm";
import Toaster from "../../components/Toaster";
import volumeIcon from "../../assets/images/Volume.svg";
import thermometerIcon from "../../assets/images/thermometer.svg";
import pipelineTempIcon from "../../assets/images/pipeline-temp.svg";
import valveIcon from "../../assets/images/EVC-valve.svg";
import hspIcon from "../../assets/images/HSP-Pressure.svg";
import lspIcon from "../../assets/images/LSP-Pressure.svg";
import subCoolTempIcon from "../../assets/images/sub-cool-temp.svg";
import tankVerticalIcon from "../../assets/images/vertical-tank.svg";
import threeLineIcon from "../../assets/images/menu.svg";
import NoTankDataAvailable from "../../components/NoTankDataAvailable";

import {
  status_tank as statusTank,
  tank_states_timer as tankStatesTimer,
} from "../../config";
import statusImgCooling from "../../assets/images/cool-icon.svg";
import statusImgWashing from "../../assets/images/washing.png";
import standByIcon from "../../assets/images/Standby_Mode.png";
import settingsIcon from "../../assets/images/settings.png";
import diagnosticIcon from "../../assets/images/Diagnostics.png";
import LiveAlertsNotifications from "../../containers/Tank/LiveAlertsNotifications";
import AlertIcon from "../../assets/images/alert-icon.svg";
import SaturatedSuctionIcon from "../../assets/images/Group 3757.svg";
import fanSpeedIcon from "../../assets/images/Group 3861.svg";
import CondensingIcon from "../../assets/images/noun-temperature-3208741.svg";
import PromptModel from "../../components/PromptModel";
import ExportCsvForm from "../../forms/ExportCsvForm/ExportCsv";
import NoDataAvailable from "../../components/NoDataAvailable";
import Evc from "./EVC";
import Hpf from "./HPF";
import Chiller from "./CHILLER"
import { rootApi } from "../../axiosConfig";

import { sensors, ranges, secondsToHourMin } from "../../actions/passwordRegex";
import {
  getTempAsPerSelectedUnitSystem,
  getPressureAsPerSelectedUnitSystem,
  getVolumeAsPerSelectedUnitSystem,
  getStringFromDecimalToBinary
} from "../../helpers";

const GraphButtonsDropdown = styled.div`
  position: absolute;
  top: 33px;
  left: -128px;
  display: ${({ show }) => (show ? "initial" : "none")};
  > span {
    display: block;
    font-size: 14px;
    color: #212f46;
    // cursor: pointer;
    padding: 5px 15px;

    @media screen and (max-width: 768px) {
      font-size: 13px;
    }
  }

  span.date-range-span {
    display: flex;
    justify-content: space-between;
  }
  width: 150px;
  z-index: 10;
  background: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 5px 0;
`;

const TankListingMobile = memo((props) => {
  const { t } = useTranslation();
  const user = getUser();
  const [agitatedTime, setAgitatedTime] = useState(null);
  const [hpfGraphData, setHPFGraphData] = useState("");
  const [chillerGraphData, setChillerGraphData] = useState("");
  const [evcGraphData, setEVCGraphData] = useState(["", "", ""]);
  const [liveData, setLiveData] = useState({
    temperature: "--",
    volume: "--",
    pipelineTemperature: "--",
    hspValue: "--",
    lspValue: "--",
    valvePos: "--",
    subCoolTemp: "--",
    saturatedSuctionTemp: "--",
    condensingTemp: "--",
    fanSpeed: "--",
    voltageInputStatus: "--",
    probeSensorStatus: "--",
  });
  const [showAddTank, setShowAddTank] = useState(false);
  const [isTankRegistered, setIsTankRegistered] = useState(true);
  const [customDates, setCustomDates] = useState([
    new Date(ranges.Yesterday[0]).toISOString(),
    new Date().toISOString(),
  ]);
  const { dairyID, tankID } = useParams();
  const [tanks, setTanks] = useState([]);
  const [dairyName, setDairyName] = useState("");
  const [dealerID, setDealerID] = useState("");
  const [currentEditTank, setCurrentEditTank] = useState({});
  const [isTankEdit, setIsTankEdit] = useState(false);
  const [deviceType, setDeviceType] = useState("CHILLER");
  const [deviceIdObj, setDeviceIdObj] = useState(null);
  const [reloadCount, setReloadCount] = useState(0);
  const [autoReloadInterval, setAutoReloadInterval] = useState(null);
  const [relIntVar, setRelIntVar] = useState(null);
  const [graphLoader, setGraphLoader] = useState(false);
  const [currentDatetime, setCurrentDatetime] = useState(new Date());
  const [dateTimeIntVar, setDateTimeIntVar] = useState(null);
  const [deviceId, setDeviceId] = useState("");
  const [tankStatusCode, setTankStatusCode] = useState("");
  const [tankStatusObj, setTankStatusObj] = useState(null);
  const [tankDeleteAlert, setTankDeleteAlert] = useState(false);
  const [tankDeleteIndex, setTankDeleteIndex] = useState(null);
  const [tanksLoader, setTanksLoader] = useState(false);
  const [drag, setDrag] = useState(false);
  const [liveAlerts, setLiveAlerts] = useState(false);
  const [showAnnotation, setShowAnnotation] = useState(true);
  const [alerts, setAlerts] = useState([]);
  const [washData, setWashData] = useState([]);
  const [currentTankData, setCurrentTankData] = useState({});
  const [showGraphButtons, setShowGraphButtons] = useState(false);
  const [configId, setConfigId] = useState(null);
  const node = useRef();

  /** Define Export csv state */
  const [show, setShow] = useState(false);
  const [exportDeviceIds, setExportDeviceIds] = useState({});
  const [tankType, setTankType] = useState(null);
  const [visible, setVisible] = useState(false);
  // const [redIndicator, setRedIndicator] = useState([]);
  const [unResolvedDevices, setUnResolvedDevices] = useState([]);
  const [tankAlert, setTankAlert] = useState([]);
  const [tankSettings, setTankSettings] = useState(false);
  const isResponsiveMode = true;

  const isMobile = useMediaQuery({
    query: "(min-device-width: 1199px)",
  });

  const isTablet = useMediaQuery({
    query: "(min-device-width: 768px)",
  });

  const [toggleShow, setToggleShow] = useState(false);

  // To toggle the three-line-menu
  const handleClickOutside = (e) => {
    if (node?.current?.contains(e.target)) {
      return;
    }
    setShowGraphButtons(false);
  };

  useEffect(() => {
    if (showGraphButtons) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showGraphButtons]);

  /** Fetch config Api call */
  useEffect(() => {
    fetchConfigs()
      .then((response) => {
        if (response.status === 200) {
          const item = response.data.find((i) =>
            i.key === "MANUAL_ANNOTATION" ? i : response.data
          );
          setConfigId(item.id);
          const sensorItems = response.data;
          const sensor = sensorItems.filter((sensorData) => sensorData.unit !== "");
          localStorage.setItem("configs", JSON.stringify(sensor));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  const getGraphData = async () => {
    setGraphLoader(true);
    let devID = "";
    if (!deviceId) {
      if (!deviceIdObj) {
        const { data } = deviceType === "HPF" ? 
                          await getTankHPFandEVCId(tankID)
                          : await getTankCHILLERandEVCId(tankID);
        setExportDeviceIds(data);
        [devID] = data[deviceType];
      } else {
        [devID] = deviceIdObj[deviceType];
      }
    } else {
      devID = deviceId;
    }
    if (deviceType === "HPF") {
      try {
        const value = await fetchGraph(
          "Custom",
          deviceType,
          sensors.HPF,
          tankID,
          devID,
          customDates[0],
          customDates[1]
        );
        setHPFGraphData(value.data.graphData);
        setWashData(value.data.washStatus);
        setGraphLoader(false);
        return;
      } catch (error) {
        console.log(error);
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong")} />
        );
      }
    } else if (deviceType === "CHILLER") {
      try {
        const value = await fetchGraphChiller(
          "Custom",
          "CHILLER",
          sensors.CHILLER,
          tankID,
          devID,
          customDates[0],
          customDates[1]
        );
        setChillerGraphData(value.data.graphData);
        // setWashData(value.data.washStatus);
        setGraphLoader(false);
        return;
      } catch (error) {
        console.log(error);
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong")} />
        );
      }
    }
    for (let i = 0; i < sensors.EVC.length; i += 1) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const res = deviceType === "HPF" ? 
          await fetchGraph(
            "Custom",
            "EVC",
            sensors.EVC[i],
            tankID,
            devID,
            customDates[0],
            customDates[1]
          )
          : await fetchGraphChiller(
            "Custom",
            "EVC",
            sensors.EVC[i],
            tankID,
            devID,
            customDates[0],
            customDates[1]
          )
        evcGraphData[i] = res.data.graphData;
      } catch (error) {
        console.log(error);
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong")} />
        );
      }
    }
    setEVCGraphData([...evcGraphData]);
    setGraphLoader(false);
  };

  const getTankDetail = async () => {
    setGraphLoader(true);
    setAgitatedTime(null);
    setDeviceIdObj(null);
    let tempDeviceID;
    if(deviceType === "HPF"){
      const { data } = await getTankHPFandEVCId(tankID);
      setDeviceType("HPF")
      if (data.HPF.length > 0) {
        const result = await getLastAgitatedValue(tankID, data.HPF[0]);
        setAgitatedTime(result?.data[0]?.time);
        setIsTankRegistered(true);
        let HPF = [];
        if (data.HPF) {
          HPF[0] = data.HPF[0];
        }
        let EVC = [];
        if (data.EVC) {
          EVC = data.EVC;
        }
        setDeviceIdObj({ HPF, EVC });
        setDeviceId(data.HPF[0]);
        tempDeviceID = data.HPF[0];
      } else {
        const { data: tankData } = await getTankById(tankID);
        if (tankData.registrationId) {
          setIsTankRegistered(false);
        } else {
          tempDeviceID = tankData.id;
          setIsTankRegistered(true);
        }
      }
      try {
        const value = await fetchGraph(
          "Custom",
          "HPF",
          sensors.HPF,
          tempDeviceID,
          data.HPF[0],
          customDates[0],
          customDates[1]
        );
        setHPFGraphData(value.data.graphData);
        setWashData(value.data.washStatus);
      } catch (error) {
        console.log(error);
        toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
      }
    } else if(deviceType === "CHILLER") {
        const { data } = await getTankCHILLERandEVCId(tankID);
        setDeviceType("CHILLER");
        if (data.CHILLER.length > 0) {
          setIsTankRegistered(true);
          let CHILLER = [];
          if (data.CHILLER) {
            CHILLER[0] = data.CHILLER[0];
          }
          let EVC = [];
          if (data.EVC) {
            EVC = data.EVC;
          }
          setDeviceIdObj({ CHILLER, EVC });
          setDeviceId(data.CHILLER[0]);
          tempDeviceID = data.CHILLER[0];
        } else {
          const { data: tankData } = await getChillerById(tankID);
          setDeviceId(tankData.deviceSerialNumber)
          if (tankData.deviceRegistrationId) {
            setIsTankRegistered(false);
          } else {
            tempDeviceID = tankData.id;
            setIsTankRegistered(true);
          }
        }
        try {
          const value = await fetchGraphChiller(
            "Custom",
            "CHILLER",
            sensors.CHILLER,
            tankID,
            tempDeviceID,
            customDates[0],
            customDates[1]
          );
          setChillerGraphData(value.data.graphData);
        } catch (error) {
          console.log(error);
          toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
        }
    }
    setGraphLoader(false);
  };

  /** Add new Tank functionality */
  const addNewTank = async (data) => {
    const temp = { ...data, dairy: dairyID, locationName: dairyID };
    try {
      const res = deviceType === "HPF" ? await addTank(temp) : await addNewChillerController(temp);
      if ((deviceType === "HPF" && dairyID === res.data.dairy.id) || (deviceType === "CHILLER" && dairyID === res.data.locationName.id)) {
        setTanks([...tanks, res.data]);
        setShowAddTank(false);
        toast.dark(
          <Toaster
            className="text-capitalize"
            icon="notify"
            message={deviceType === "HPF" ? t("tank_added") : t("chiller_added")}
          />,
          { autoClose: 3000, hideProgressBar: true }
        );
      }
    } catch (err) {
      console.log(err);
      toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
    }
  };
  /** Edit Tank functionality */
  const editTank = async (id, data) => {
    const temp = { ...data, dairy: dairyID, locationName: dairyID };
    try {
      const res = deviceType === "HPF" ? await updateTank(id, temp) : await updateChiller(id, temp);
      if ((deviceType === "HPF" && dairyID === res.data.dairy.id) || (deviceType === "CHILLER" && dairyID === res.data.locationName.id)) {
        const resObj = deviceType === "HPF" ? await getTanks(dairyID) : await getChillers(dairyID);
        setTanks(resObj.data);
        setShowAddTank(false);
        toast.dark(
          <Toaster
            className="text-capitalize"
            icon="notify"
            message={deviceType === "HPF" ? t("tank_deleted") : t("chiller_deleted")}
          />,
          { autoClose: 3000, hideProgressBar: true }
        );
      }
    } catch (error) {
      console.log(error);
      toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
    }
  };

  /** Remove tank functionality  */
  const removeTank = async (index) => {
    const { location, history } = props;
    const currentTankId = tanks[index].id;
    try {
      deviceType === "HPF" ? await deleteTank(currentTankId) : await deleteChiller(currentTankId);
      const res = deviceType === "HPF" ? await getTanks(dairyID) : await getChillers(dairyID);
      setTankDeleteAlert(false);
      setTanks(res.data);
      toast.dark(
        <Toaster
          className="text-capitalize"
          icon="notify"
          message={deviceType === "HPF" ? t("tank_deleted") : t("chiller_deleted")}
        />,
        { autoClose: 3000, hideProgressBar: true }
      );
    } catch (error) {
      console.log(error);
      toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
    }
  };

  const handleDateCallback = (start, end, label) => {
    customDates[0] = start.toISOString();
    customDates[1] = end.toISOString();
    setCustomDates([...customDates]);
  };

  const onBeforeDragStart = () => {
    setDrag(true);
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;
    setDrag(false);
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }
    const newTankOrder = Array.from(tanks);
    const [replaceItem] = newTankOrder.splice(source.index, 1);
    newTankOrder.splice(destination.index, 0, replaceItem);
    setTanks([...newTankOrder]);
  };

  const tick = () => {
    setCurrentDatetime(new Date());
  };

  const setStatusTank = () => {
    let image;
    let textOne;
    let textTwo;
    let textThree;
    let color = "black";
    let hpfFan = "0";
    let mainTimer;
    let timerVal;
    let pump1;
    let pump2;
    if (
      currentTankData &&
      currentTankData["CHILLER"] &&
      Object.keys(currentTankData.CHILLER).length
    ) {
      pump1 = getStringFromDecimalToBinary(
        currentTankData.CHILLER[Object.keys(currentTankData.CHILLER)[0]]
          .CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS,
        11
      );
      pump2 = getStringFromDecimalToBinary(
        currentTankData.CHILLER[Object.keys(currentTankData.CHILLER)[0]]
          .CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS,
        13
      );
    }
    if (tankStatusCode !== "") {
      if (statusTank[tankStatusCode].indexOf("Washing") > -1) {
        image = statusImgWashing;
        mainTimer = (
          <span className="timerTag">
            (
            {secondsToHourMin(
              currentTankData.HPF[Object.keys(currentTankData.HPF)[0]]
                .HPF_INPUT_REG_WASH_TIME_REMAINING
            )}
            )
          </span>
        );
      } else if (statusTank[tankStatusCode].indexOf("Cooling") > -1) {
        textOne = t("cooling");
        image = statusImgCooling;
        color = "#2c61a5";
        const minToSec =
          currentTankData.HPF[Object.keys(currentTankData.HPF)[0]]
            .HPF_INPUT_REG_COOL_CYCLE_TIMER * 60;

        mainTimer =
          minToSec !== "" && tankStatusCode > 2002 ? (
            <span className="timerTag">
              {minToSec === "" ? "" : secondsToHourMin(minToSec)}
            </span>
          ) : null;
      } else if (statusTank[tankStatusCode].indexOf("Standby") > -1) {
        image = standByIcon;
      } else if (
        statusTank[tankStatusCode].indexOf("Editing Parameters") > -1 ||
        statusTank[tankStatusCode].indexOf("Setup") > -1
      ) {
        image = settingsIcon;
      } else if (statusTank[tankStatusCode] === "Diagnostic") {
        image = diagnosticIcon;
      }
      hpfFan =
        currentTankData.HPF[Object.keys(currentTankData.HPF)[0]]
          .AGITATOR_MOTOR_RELAY;

      textOne = t(statusTank[tankStatusCode].split(" - ")[0]);
      textTwo = t(statusTank[tankStatusCode].split(" - ")[1]);
      textThree = t(statusTank[tankStatusCode].split(" - ")[2]);
      if (currentTankData !== null && tankStatusCode !== "") {
        let timerName = tankStatesTimer[tankStatusCode.toString()];
        timerVal =
          currentTankData.HPF[Object.keys(currentTankData.HPF)[0]][timerName];
      }
    } else {
      mainTimer = "";
    }

    const temp = {
      image,
      textOne,
      textTwo,
      textThree,
      color,
      hpfFan,
      mainTimer,
      timerVal,
      pump1,
      pump2,
    };
    setTankStatusObj(temp);
  };

  const getAlerts = async () => {
    try {
      const startTime = Date.parse(customDates[0]);
      const endTime = Date.parse(customDates[1]);
      const res =  deviceType === "HPF" ? await getTankAlerts(tankID, startTime, endTime) : await getChillerAlerts(tankID, startTime, endTime);
      setAlerts(res.data);
    } catch (error) {
      console.error(error);
      toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
    }
  };

  useEffect(() => {
    if (tankID) {
      getTankDetail();
      getAlerts();
    }
  }, [tankID]);

  useEffect(() => {
    setLiveData({
      temperature: "--",
      volume: "--",
      pipelineTemperature: "--",
      hspValue: "--",
      lspValue: "--",
      valvePos: "--",
      subCoolTemp: "--",
      saturatedSuctionTemp: "--",
      condensingTemp: "--",
      fanSpeed: "--",
      voltageInputStatus: "--",
      probeSensorStatus: "--",
      pump1: null,
      pump2: null
    });
    setTankStatusObj(null);
    io.socket.on(`influxData:${tankID}`, (data) => {
      let statusNumber = "";
      let temp;
      if (deviceType === "CHILLER" && data?.CHILLER) {
        const temperature =
          data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_TEMP_1;
        
        const pump1 = getStringFromDecimalToBinary(data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS, 11);
        const pump2 = getStringFromDecimalToBinary(data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS, 13);
        const voltageInputStatus = getStringFromDecimalToBinary(data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_VOLTAGE_INPUT_STATUS, 0);
        const probeSensorStatus = getStringFromDecimalToBinary(data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_PROBE_SENSOR_STATUS, 0);
        temp = {
          temperature,
          pump1,
          pump2,
          voltageInputStatus,
          probeSensorStatus
        };
      }
      if (deviceType === "HPF" && data?.HPF) {
        statusNumber =
        data.HPF[Object.keys(data.HPF)[0]].HPF_INPUT_REG_MAIN_STATE;
        const temperature =
          data.HPF[Object.keys(data.HPF)[0]].HPF_INPUT_REG_TEMP1;
        const volume =
          data.HPF[Object.keys(data.HPF)[0]]
            .HPF_INPUT_REG_VOLUME_4TO20MA_READING ||
            Number(
              data.HPF[Object.keys(data.HPF)[0]]
                .HPF_INPUT_REG_VOLUME_4TO20MA_READING
            ) === 0
            ? data.HPF[Object.keys(data.HPF)[0]]
              .HPF_INPUT_REG_VOLUME_4TO20MA_READING
            : "--";
        const pipelineTemperature =
          data.HPF[Object.keys(data.HPF)[0]].HPF_INPUT_REG_TEMP2;
        temp = {
          temperature,
          volume,
          pipelineTemperature,
        };
      }
      if (deviceType === "EVC" && data?.EVC) {
        const hspValue =
          data?.EVC[deviceId || Object.keys(data.EVC)[0]]
            ?.EVC_INPUT_REG_HIGH_SIDE_PRESSURE;

        const lspValue =
          data?.EVC[deviceId || Object.keys(data.EVC)[0]]
            ?.EVC_INPUT_REG_LOW_SIDE_PRESSURE;

        const valvePos =
          data.EVC[deviceId || Object.keys(data.EVC)[0]]
            ?.EVC_INPUT_REG_VALVE_POS;

        const subCoolTemp =
          data.EVC[deviceId || Object.keys(data.EVC)[0]]
            ?.EVC_INPUT_REG_SUBCOOL_TEMP;

        const saturatedSuctionTemp =
          data.EVC[deviceId || Object.keys(data.EVC)[0]]
            ?.EVC_INPUT_REG_LO_SAT_TEMP;

        const condensingTemp =
          data.EVC[deviceId || Object.keys(data.EVC)[0]]
            ?.EVC_INPUT_REG_HI_SAT_TEMP;

        const fanSpeed =
          data.EVC[deviceId || Object.keys(data.EVC)[0]]
            ?.EVC_INPUT_REG_FAN_OUTPUT;

        temp = {
          subCoolTemp,
          hspValue,
          condensingTemp,
          lspValue,
          saturatedSuctionTemp,
          valvePos,
          fanSpeed
        };
      }
      setCurrentTankData(data);
      setTankStatusCode(statusNumber);
      setLiveData({ ...liveData, ...temp });
      setTankType(data.tankType);
    });

    io.socket.on();
    if(deviceType === 'HPF') {
      io.socket.on(`alerthistory:manual:${dairyID}`, (data) => {
        /** Display live alerts in devices */
        let resolvedDevices = [];
        if (data.event === "update") {
          resolvedDevices = data.data.map((i) => {
            if (i.isResolved) {
              return i.deviceID;
            } else {
              return "";
            }
          });
          resolvedDevices = resolvedDevices.filter(Boolean);
          setUnResolvedDevices((items) => {
            let tmpItems = items.filter(function (el, index, arr) {
              return index == arr.indexOf(el);
            });
            return tmpItems.filter((i) => !resolvedDevices.includes(i));
          });
        } else {
          setUnResolvedDevices((items) => {
            return [...items, data.data.deviceID];
          });
        }
        /** Graph reloaded when alerts display */
        let alerts = [];
        if (data.event === "update") {
          alerts = data.data.filter((el) => el.tank === tankID);
        } else {
          alerts.push(data.data);
        }
        if (alerts.length > 0) {
          setAlerts((prev) => [...prev, ...alerts]);
          getGraphData();
        }
        /** function call when socket connection generate */
        updateTankAlert();
      });
    } else {
      io.socket.on(`chilleralerthistory:manual:${dairyID}`, (data) => {
        /** Display live alerts in devices */
        let resolvedDevices = [];
        if (data.event === "update") {
          resolvedDevices = data.data.map((i) => {
            if (i.isResolved) {
              return i.deviceID;
            } else {
              return "";
            }
          });
          resolvedDevices = resolvedDevices.filter(Boolean);
          setUnResolvedDevices((items) => {
            let tmpItems = items.filter(function (el, index, arr) {
              return index == arr.indexOf(el);
            });
            return tmpItems.filter((i) => !resolvedDevices.includes(i));
          });
        } else {
          setUnResolvedDevices((items) => {
            return [...items, data.data.deviceID];
          });
        }
        /** Graph reloaded when alerts display */
        let alerts = [];
        if (data.event === "update") {
          alerts = data.data.filter((el) => el.tank === tankID);
        } else {
          alerts.push(data.data);
        }
        if (alerts.length > 0) {
          setAlerts((prev) => [...prev, ...alerts]);
          getGraphData();
        }
        /** function call when socket connection generate */
        updateTankAlert();
      });
    }
    return () => {
      io.socket.removeAllListeners();
    };
  }, [tankID, deviceId]);

  useEffect(() => {
    setStatusTank();
  }, [tankID, tankStatusCode, currentTankData]);

  useEffect(() => {
    const { location, history } = props;
    setTanksLoader(true);
    getTanks(dairyID)
      .then((data) => {
        setTanks(data.data);
        setTanksLoader(false);
      })
      .catch((err) => {
        console.log(err);
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong")} />
        );
      });
  }, [dairyID]);

  useEffect(() => {
    fetchDairy(dairyID)
      .then((data) => {
        setDairyName(data.data.name);
        setDealerID(data.data.createdBy?.id)
      })
      .catch((err) => {
        console.log(err);
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong")} />
        );
      });
  }, [dairyID]);

  useEffect(() => {
    getGraphData();
    setTankStatusObj(null);
  }, [tankID, deviceId]);

  useEffect(() => {
    if (reloadCount !== 0) {
      getGraphData();
      getAlerts();
    }
  }, [reloadCount]);

  useEffect(() => {
    if (autoReloadInterval) {
      if (relIntVar) {
        clearInterval(relIntVar);
      }
      setRelIntVar(
        setInterval(() => {
          tick();
          getGraphData();
          getAlerts();
        }, autoReloadInterval * 1000)
      );
    } else if (relIntVar) {
      clearInterval(relIntVar);
    }
    return () => {
      if (relIntVar) {
        clearInterval(relIntVar);
      }
    };
  }, [autoReloadInterval]);

  useEffect(() => {
    setDateTimeIntVar(setInterval(tick, 10000));
    return () => {
      clearInterval(dateTimeIntVar);
    };
  }, []);
  const liveDataHelper = {
    CHILLER: [
      // {
      //   icon: volumeIcon,
      //   name: t("tank_volume"),
      //   value: getVolumeAsPerSelectedUnitSystem(Number(liveData.volume)).volume,
      //   unit: getVolumeAsPerSelectedUnitSystem(Number(liveData.volume))
      //     .volumeUnit,
      // },
      {
        icon: thermometerIcon,
        name: t("tank_temperature"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.temperature))
          .temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.temperature))
          .tempUnit,
      },
      {
        icon: SaturatedSuctionIcon,
        name: t("voltage_input_status"),
        value: liveData.voltageInputStatus ? "ON" : "OFF",
        unit: liveData.voltageInputStatus,
        isLabel: true
      },
      {
        icon: hspIcon,
        name: t("probe_sensor_status"),
        value: liveData.probeSensorStatus ? "PRESENT" : "ABSENT",
        unit: liveData.voltageInputStatus,
        isLabel: true
      },
      // {
      //   icon: pipelineTempIcon,
      //   name: t("pipeline_temperature"),
      //   value: getTempAsPerSelectedUnitSystem(
      //     Number(liveData.pipelineTemperature)
      //   ).temperature,
      //   unit: getTempAsPerSelectedUnitSystem(
      //     Number(liveData.pipelineTemperature)
      //   ).tempUnit,
      // },
    ],
    HPF: [
      {
        icon: volumeIcon,
        name: t("tank_volume"),
        value: getVolumeAsPerSelectedUnitSystem(Number(liveData.volume)).volume,
        unit: getVolumeAsPerSelectedUnitSystem(Number(liveData.volume))
          .volumeUnit,
      },
      {
        icon: thermometerIcon,
        name: t("tank_temperature"),
        value: getTempAsPerSelectedUnitSystem(Number(liveData.temperature))
          .temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.temperature))
          .tempUnit,
      },
      {
        icon: pipelineTempIcon,
        name: t("pipeline_temperature"),
        value: getTempAsPerSelectedUnitSystem(
          Number(liveData.pipelineTemperature)
        ).temperature,
        unit: getTempAsPerSelectedUnitSystem(
          Number(liveData.pipelineTemperature)
        ).tempUnit,
      },
    ],
    EVC: [
      {
        icon: subCoolTempIcon,
        name: t("subcool_temperature"),
        value: getTempAsPerSelectedUnitSystem(
          Number(liveData.subCoolTemp),
          true
        ).temperature,
        unit: getTempAsPerSelectedUnitSystem(Number(liveData.subCoolTemp), true)
          .tempUnit,
      },
      {
        icon: hspIcon,
        name: t("HSP"),
        value: getPressureAsPerSelectedUnitSystem(Number(liveData.hspValue))
          .pressure,
        unit: getPressureAsPerSelectedUnitSystem(Number(liveData.hspValue))
          .pressureUnit,
      },
      {
        icon: CondensingIcon,
        name: t("sct"),
        value: getPressureAsPerSelectedUnitSystem(Number(liveData.condensingTemp))
          .pressure,
        unit: getPressureAsPerSelectedUnitSystem(Number(liveData.condensingTemp))
          .pressureUnit,
      },
      {
        icon: lspIcon,
        name: t("LSP"),
        value: getPressureAsPerSelectedUnitSystem(Number(liveData.lspValue))
          .pressure,
        unit: getPressureAsPerSelectedUnitSystem(Number(liveData.lspValue))
          .pressureUnit,
      },
      {
        icon: SaturatedSuctionIcon,
        name: t("sst"),
        value: getPressureAsPerSelectedUnitSystem(Number(liveData.saturatedSuctionTemp))
          .pressure,
        unit: getPressureAsPerSelectedUnitSystem(Number(liveData.saturatedSuctionTemp))
          .pressureUnit,
      },
      {
        icon: valveIcon,
        name: t("valve_position"),
        value: liveData.valvePos,
        unit: "",
      },
      {
        icon: fanSpeedIcon,
        name: t("fan_speed"),
        value: liveData.fanSpeed,
        unit: "",
      },
    ],
  };

  const toggleRegistrationCode = async (id, data) => {
    let type = "";
    let message = "";
    if (data.HPFSerialNumber) {
      if (!data.registrationId) {
        // Generate Re-register Tank Button
        type = "GENERATE_REG_CODE";
        message = t("re_registers_successfully");
      } else {
        // Cancel Re-register Tank Button
        type = "DELETE_REG_CODE";
        message = t("cancel_registers_successfully");
      }
    }
    data.calibrationChart = data.calibrationChart ? data.calibrationChart.id : null
    const temp = {
      ...data,
      updateRegistrationCode: type,
      dairy: dairyID,
    };
    try {
      const res = await updateTank(id, temp);
      if (dairyID === res.data.dairy.id) {
        const resObj = await getTanks(dairyID);
        setTanks(resObj.data);
        toast.dark(<Toaster icon="notify" message={message} />, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
    }
  };

  /** Show hide section in mobile */
  useEffect(() => {
    if (!tankID) {
      setToggleShow(true);
    } else {
      setToggleShow(false);
    }
  }, [tankID]);

  /** Show red indicator in device icon */
  useEffect(() => {
    if(deviceType === "HPF") {
      rootApi
        .get(
          `/alerthistories?where={"and":[{"alert":null},{"dairy":"${dairyID}"},{"tank":"${tankID}"},{"deviceType":{"!=":""}},{"isResolved":false}, {"configKey":{ "!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`
        )
        .then((response) => {
          let devices = [];
          response.data.map((item) => {
            if (item.tank && item.deviceID !== "") {
              if (!devices.includes(item.deviceID)) {
                devices.push(item.deviceID);
              }
            }
          });
          setUnResolvedDevices(devices);
        })
        .catch((err) => {
          console.error(err);
          toast.dark(
            <Toaster icon="error" message={t("live_alert_history_error")} />
          );
        });
    } else {
      rootApi
      .get(
        `/chilleralerthistories?where={"and":[{"chillerAlert":null},{"location":"${dairyID}"},{"device":"${tankID}"},{"deviceType":{"!=":""}},{"isResolved":false}, {"configKey":{ "!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`
      )
      .then((response) => {
        let devices = [];
        response.data.map((item) => {
          if (item.tank && item.deviceID !== "") {
            if (!devices.includes(item.deviceID)) {
              devices.push(item.deviceID);
            }
          }
        });
        setUnResolvedDevices(devices);
      })
      .catch((err) => {
        console.error(err);
        toast.dark(
          <Toaster icon="error" message={t("live_alert_history_error")} />
        );
      });
    }
  }, [tankID]);

  /** Display live alert in tank card and alert icon */
  const updateTankAlert = useCallback(() => {
    if(deviceType === "HPF") {
      io.socket.get(
        `/api/alerthistories?where={"and":[{"alert":null},{"dairy":"${dairyID}"},{"deviceType":{"!=":""}},{"isResolved":false}, {"configKey":{ "!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`,
        (data) => {
          let tankIds = data.map((i) => i.tank.id);
          setTankAlert(tankIds);
        }
      );
    } else {
      io.socket.get(
        `/api/chilleralerthistories?where={"and":[{"chillerAlert":null},{"location":"${dairyID}"},{"deviceType":{"!=":""}},{"isResolved":false}, {"configKey":{ "!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`,
        (data) => {
          let tankIds = data.map((i) => i.tank.id);
          setTankAlert(tankIds);
        }
      );
    }
  });
  useEffect(updateTankAlert, []);
  return (
    <>
      <Row className="row-full-width">
        {!toggleShow ? (
          <Col xl={8} lg={12} md={12} className="second-section">
            {tankID ? (
              isTankRegistered ? (
                <>
                  <div className="d-flex align-items-center graph-mobile-device">
                    <div
                      className={
                        deviceIdObj === null
                          ? "graph-device pl-0 pr-2"
                          : "graph-device px-2"
                      }
                    >
                      {deviceIdObj &&
                        Object.keys(deviceIdObj).map((key) => {
                          return deviceIdObj[key].map((id, index) => {
                            const evcRotateSignal = Number.parseInt(currentTankData?.EVC?.[id]?.RUN_SIGNAL) !== 0;
                            let isAlert = unResolvedDevices.includes(id);
                            return (
                              <div
                                className={
                                  deviceId === id
                                    ? "mobile-device-image-active"
                                    : "mobile-device-image"
                                }
                                key={id}
                              >
                                {tankType === null ? (
                                  <div
                                    className="active offline offlineTank pt-1 pb-1"
                                    onClick={() => {
                                      if (
                                        deviceType === key &&
                                        deviceId === id
                                      ) {
                                        return;
                                      }
                                      setDeviceType(`${key}`);
                                      setDeviceId(`${id}`);
                                      setLiveAlerts(false);
                                      setTankSettings(false);
                                    }}
                                  >
                                    <span
                                      className={
                                        key === "HPF" || key === "CHILLER"
                                          ? "single-tank-page"
                                          : "evc-img"
                                      }
                                    >
                                      --
                                    </span>
                                  </div>
                                ) : (
                                    <span
                                      className={
                                        isAlert
                                          ? "tank-red-indicator position-relative"
                                          : ""
                                      }
                                    >
                                      <Image
                                        className={
                                          key === "HPF" || key === "CHILLER"
                                            ? tankType == "1"
                                              ? "vertical-image"
                                              : "hpf-image"
                                            : "evc-image"
                                        }
                                        src={
                                          key === "HPF" || key === "CHILLER"
                                            ? tankType == "1"
                                              ? tankVerticalIcon
                                              : key === "HPF" ? TankHPF : TankCHILLER
                                            : key === "HPF" ? TankTwo : evcRotateSignal
                                              ? EVC_GIF_ICON
                                              : EVC_ICON
                                        }
                                        onClick={() => {
                                          if (
                                            deviceType === key &&
                                            deviceId === id
                                          ) {
                                            return;
                                          }
                                          setDeviceType(`${key}`);
                                          setDeviceId(`${id}`);
                                          setLiveAlerts(false);
                                          setTankSettings(false);
                                        }}
                                      />
                                    </span>
                                  )}
                              </div>
                            );
                          });
                        })}
                      <div>
                        {deviceIdObj &&
                          (deviceType === "HPF" ? deviceIdObj?.HPF : deviceIdObj?.CHILLER) !== null &&
                          deviceIdObj?.EVC.length !== null && (
                            <div class="vertical-line mx-2" />
                          )}

                        <Button
                          className={`${liveAlerts === true
                            ? "mr-auto mt-1 px-2 alert-btn-active"
                            : "mr-auto mt-1 px-2 alert-btn"
                            }`}
                          variant="none"
                          onClick={() => {
                            setLiveAlerts(!liveAlerts);
                            setDeviceId(false);
                            setTankSettings(false)
                          }}
                        >
                          <span
                            className={`${tankAlert.includes(tankID)
                              ? "alert-red-indicator position-relative"
                              : "text-primary"
                              }`}
                          >
                            <Image
                              src={AlertIcon}
                              style={{ width: "24px", height: "24px" }}
                            />
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>

                  <Row>
                    <Col
                      md={12}
                      sm={12}
                      className="d-flex justify-content-between pt-3 "
                    >
                      <div>
                        <Button
                          className="border-primary text-primary"
                          variant="outline-none"
                          type="button"
                          onClick={() => setVisible(true)}
                        >
                          {t("live_data")}
                        </Button>{" "}
                      </div>
                      <div className="position-relative pr-3">
                        <Image
                          src={threeLineIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowGraphButtons(!showGraphButtons);
                          }}
                        />
                        <GraphButtonsDropdown show={showGraphButtons}>
                          <span
                            className="text-capitalize"
                            onClick={(e) => {
                              e.stopPropagation();
                              setCustomDates([
                                new Date(ranges.Yesterday[0]).toISOString(),
                                new Date().toISOString(),
                              ]);
                              setReloadCount(reloadCount + 1);
                            }}
                          >
                            {t("reload")}
                          </span>
                          <span className="d-lg-block d-md-block d-none">
                            <div
                              className="white"
                              show={showAnnotation}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (showAnnotation) {
                                  setShowAnnotation(false);
                                } else {
                                  setShowAnnotation(true);
                                }
                              }}
                            >
                              {showAnnotation
                                ? t("hide_annotation")
                                : t("show_annotation")}
                            </div>
                          </span>
                          <span
                            onClick={() => {
                              setShow(true);
                              setCurrentEditTank(() =>
                                tanks.find((i) => i.id === tankID)
                              );
                            }}
                          >
                            {t("export_csv")}
                          </span>
                          <span className="date-range-span">
                            <DateRangePicker
                              initialSettings={{
                                autoUpdateInput: false,
                                timePicker: true,
                                startDate: new Date(),
                                endDate: new Date(),
                                ranges,
                                opens: "left",
                              }}
                              onApply={() => {
                                getGraphData();
                                getAlerts();
                              }}
                              onCallback={handleDateCallback}
                            >
                              <span>
                                {`${moment(customDates[0]).format(
                                  "DD MMM"
                                )} - ${moment(customDates[1]).format(
                                  "DD MMM"
                                )}`}
                              </span>
                            </DateRangePicker>
                            <Image src={downCaret} alt="" />
                          </span>
                        </GraphButtonsDropdown>
                      </div>
                    </Col>
                  </Row>
                  {tankSettings === true && liveAlerts === false && (
                  <Row>
                    <Col lg={12}>
                      <Settings />
                    </Col>
                  </Row>
                )}

                  {liveAlerts === true && tankSettings === false && (
                    <Row>
                      <Col lg={12}>
                        <LiveAlertsNotifications
                          currentTankData={currentTankData}
                          dropdownTime={props.dropdownTime}
                        />
                      </Col>
                    </Row>
                  )}

                  <Chiller
                    graphLoader={graphLoader}
                    deviceType={deviceType}
                    liveAlerts={liveAlerts}
                    chillerGraphData={chillerGraphData}
                    sensors={sensors}
                    showAnnotation={showAnnotation}
                    washData={washData}
                    alerts={alerts}
                    getAlerts={getAlerts}
                    configId={configId}
                    dropdownTime={props.dropdownTime}
                  />

                  <Hpf
                    graphLoader={graphLoader}
                    deviceType={deviceType}
                    liveAlerts={liveAlerts}
                    hpfGraphData={hpfGraphData}
                    sensors={sensors}
                    showAnnotation={showAnnotation}
                    washData={washData}
                    alerts={alerts}
                    configId={configId}
                    dropdownTime={props.dropdownTime}
                    getAlerts={getAlerts}
                    isResponsiveMode={isResponsiveMode}
                  />

                  <Evc
                    graphLoader={graphLoader}
                    deviceType={deviceType}
                    liveAlerts={liveAlerts}
                    evcGraphData={evcGraphData}
                    sensors={sensors}
                    showAnnotation={showAnnotation}
                    washData={washData}
                    alerts={alerts}
                    configId={configId}
                    dropdownTime={props.dropdownTime}
                    getAlerts={getAlerts}
                    isResponsiveMode={isResponsiveMode}
                  />
                </>
              ) : (
                  <center>
                    <NoDataAvailable title={deviceType === "HPF" ? t("tank_not_registered") : t("chiller_not_registered")} />
                  </center>
                )
            ) : (
                <center>
                  <NoDataAvailable title={t("no_data")} />
                </center>
              )}
          </Col>
        ) : (
            <Col xl={2} lg={12} md={12} className="first-section">
              {tanksLoader ? (
                <TanksLoader />
              ) : (
                  <>
                    <Row className="mb-3">
                      {tanks.length === 0 && (
                        <Col lg={12} className="d-lg-block d-md-block text-center">
                          {user.role === "MilkHauler" ||
                            user.role === "Sanitarian" ? (
                              ""
                            ) : (
                              <NoTankDataAvailable
                                setIsTankEdit={setIsTankEdit}
                                setShowAddTank={setShowAddTank}
                              />
                            )}
                        </Col>
                      )}
                      <Col lg={9} sm={12} md={8} />
                      <Col
                        lg={3}
                        sm={12}
                        md={4}
                        className="d-lg-block d-md-block d-none"
                      >
                        {user.role === "MilkHauler" || user.role === "Sanitarian"
                          ? ""
                          : tanks.length > 0 && (
                            <Button
                              variant="primary"
                              size="lg"
                              type="button"
                              block
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsTankEdit(false);
                                setShowAddTank(true);
                              }}
                            >
                              <Image
                                src={addTankIcon}
                                className="mr-2 pb-1"
                                fluid
                              />
                              {t("add_new_tank")}
                            </Button>
                          )}
                      </Col>
                    </Row>
                    <Row>
                      {tanks.map((tank, index) => {
                        const { location, history } = props;
                        const isAlert = tankAlert.includes(tank.id);
                        return (
                          <Col lg={3} md={4} key={tank.id}>
                            <TankCard
                              click={() => {
                                let url = location.pathname.split("/");
                                url.splice(8, 1);
                                if (index === tanks.length - 1) {
                                  url.push(tank.id);
                                } else {
                                  url.push(tank.id);
                                }
                                url = url.join("/");
                                history.replace(url);
                                if(deviceType === "HPF"){
                                  setDeviceType("HPF")
                                  setDeviceId(deviceIdObj?.HPF[0]);
                                }else{
                                  setDeviceType("CHILLER")
                                  setDeviceId(deviceIdObj?.CHILLER[0]);
                                }
                                setLiveAlerts(false);
                                setTankSettings(false);
                                setToggleShow((prevToggle) => !prevToggle);
                              }}
                              remove={() => {
                                setTankDeleteAlert(true);
                                setTankDeleteIndex(index);
                              }}
                              editTank={() => {
                                setIsTankEdit(true);
                                setCurrentEditTank({ ...tank });
                                setShowAddTank(true);
                              }}
                              tank={tank}
                              deviceType={deviceType}
                              isactive={tankID === tank.id}
                              toggleRegistrationCode={() =>
                                toggleRegistrationCode(tank.id, tank)
                              }
                              isAlert={isAlert}
                              setTankSettings={setTankSettings}
                              setLiveAlerts={setLiveAlerts}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                    <Row
                      className="mx-md-auto text-center position-sticky d-md-none d-lg-none"
                      style={{ bottom: "0" }}
                    >
                      <Col lg={6} sm={12} md={6}>
                        {user.role === "MilkHauler" ||
                          user.role === "Sanitarian" ? (
                            ""
                          ) : (
                            <>
                              {tanks.length === 0 ? (
                                ""
                              ) : (
                                  <Button
                                    className="mt-4 mb-2"
                                    variant="primary"
                                    size="lg"
                                    type="button"
                                    block
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setIsTankEdit(false);
                                      setShowAddTank(true);
                                    }}
                                  >
                                    <Image
                                      src={addTankIcon}
                                      className="mr-2 pb-1"
                                      fluid
                                    />
                                    {deviceType === "HPF" ? t("add_new_tank") : t("add_device")}
                                  </Button>
                                )}
                            </>
                          )}
                      </Col>
                    </Row>
                  </>
                )}
            </Col>
          )}

        <Drawer
          title={t("live_data")}
          placement="bottom"
          closable={true}
          onClose={() => setVisible(false)}
          visible={visible}
          className="live-data"
        >
          <LiveDataSideBar
            tankStatusObj={tankStatusObj}
            agitatedTime={agitatedTime}
            liveDataHelper={liveDataHelper}
            deviceType={deviceType}
            secondsToHourMin={secondsToHourMin}
            currentTankData={currentTankData}
            configId={configId}
            dropdownTime={props.dropdownTime}
          />
        </Drawer>
      </Row>
      { deviceType === "HPF" ? 
          <AddTankForm
            dealerID={dealerID}
            show={showAddTank}
            close={() => setShowAddTank(false)}
            dairy={dairyName}
            addNewTank={addNewTank}
            edit={isTankEdit}
            tank={currentEditTank}
            editTank={editTank}
          /> : <AddDeviceControlForm
              dealerID={dealerID}
              show={showAddTank}
              close={() => setShowAddTank(false)}
              dairy={dairyName}
              addNewTank={addNewTank}
              edit={isTankEdit}
              tank={currentEditTank}
              editTank={editTank}
            /> 
        }
      <PromptModel
        open={tankDeleteAlert}
        closeAlert={() => {
          setTankDeleteAlert(false);
        }}
        modelContent={t("are_you_sure")}
        callback={() => {
          if (tankDeleteIndex !== null) {
            removeTank(tankDeleteIndex);
          }
        }}
      />
      {show && (
        <ExportCsvForm
          showModel={show}
          closeModel={() => setShow(false)}
          tank={currentEditTank}
          exportDeviceIds={exportDeviceIds}
          dropdownTime={props.dropdownTime}
        />
      )}
    </>
  );
});
TankListingMobile.propTypes = {
  location: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
};
export default withRouter(TankListingMobile);

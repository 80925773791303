import React, { memo } from "react";
import { Row, Col, Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const CalibrationReportModel = memo(
  ({ showModel, closeModel, calibrationData }) => {
    const { t } = useTranslation();
    const tableStructure = [
      { colName: t("tank_height"), dataKey: "AccumulatedHeight", colUnit: t("inch") },
      { colName: t("volume_increment"), dataKey: "VolumeIncrement", colUnit: t("gal_usa") },
      { colName: t("accumulated_volume"), dataKey: "AccumulatedVolume", colUnit: t("gal_usa") },
    ];

    return (
      <Modal
        show={showModel}
        onHide={closeModel}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header className="bg-primary" closeButton>
          <Modal.Title className="lead text-capitalize text-white mb-0 font-weight-bold">
            {calibrationData.chartName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="report-model">
            <Col lg={12}>
              <Table responsive borderless className="global-table">
                <thead className="border-bottom">
                  <tr className="text-dark-gray">
                    {tableStructure.map((col, i) => {
                      return (
                        <th className="font-weight-normal" key={i}>
                          {col.colName}<br />
                          ({col.colUnit})
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {calibrationData?.chart !== null &&
                    calibrationData?.chart.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td>{data ? data["Height (inch)"] : ''}</td>
                          <td>{data ? data["Volume Increment (gal US)"] : ''}</td>
                          <td>{data ? data["Accumulated Volume (gal US)"] : ''}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>
          {calibrationData?.chart === null && calibrationData?.chart < 1 && (
            <div className="text-center lead">{t("data_not_available")}</div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
);

CalibrationReportModel.propTypes = {
  showModel: PropTypes.bool,
  closeModel: PropTypes.func.isRequired,
};

CalibrationReportModel.defaultProps = {
  showModel: false,
};

export default CalibrationReportModel;

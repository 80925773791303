import React, { memo, useState } from "react";
import {
  Card,
  Dropdown as BootstrapDropdown,
  Image,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import horizontalTank from "../../assets/images/tank-new.png";
import tankVerticalIcon from "../../assets/images/vertical-tank.svg";
import tankIcon from "../../assets/images/Multi-Stage-Chiller-Control-panel-1.png";
import gycolIcon from "../../assets/images/noun-glass-4565827.svg";
import productIcon from "../../assets/images/Milk-new.svg";
import arrowIcon from "../../assets/images/noun-arrow-2304592.svg";
import io from "../../actions/socket";
import Dot from "../../assets/images/dot.svg";
import { getUser } from "../../actions/actions";
import {
  getTempAsPerSelectedUnitSystem,
  getVolumeAsPerSelectedUnitSystem,
  getPressureAsPerSelectedUnitSystem
} from "../../helpers";
import { useMediaQuery } from "react-responsive";

const TankCardImg = styled.img`
  width: 40px;
  height: 40px;
`;

const CardBody = styled(Card.Body)`
  padding: 15px 12px 15px 12px;
  background: ${(props) => (props.isactive ? "#f0f6ff" : "white")};
  border-left: ${(props) => (props.isactive ? "3px solid #2c61a5" : "initial")};
  cursor: pointer;
  &:hover {
    background-color: #eff5ff;
  }
`;

const UnregTankCard = styled(Card.Body)`
  padding: 15px 12px 15px 12px;
  cursor: not-allowed;
  border: 1px solid #d6e6ff;
  border-radius: 0.25rem;
  p {
    font-size: 18px;
    letter-spacing: 9.6px;
    font-weight: bold;
  }
`;

const TankOptions = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  .dropdown-toggle img {
    padding: 5px !important;
    &:hover {
      border-radius: 2px;
      background-color: #adcdff;
    }
  }

  .dropdown-toggle::after {
    content: none;
    &:hover {
      background: #adcdff;
    }
  }

  button.tank-option-btn {
    font-size: 14px;
    color: #212f46;
    &:hover {
      background-color: #f0f6ff;
    }
  }
  .edit-dropdown {
    transform: translate(-117px, 28px) !important;
  }
`;

const TankInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-item: center;
`;

const GycolInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  font-size: 10px
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  font-size: 10px
`;

const GycolImg = styled.img`
  width: 12px;
  height: 12px;
`;

const ProductImg = styled.img`
  width: 12px;
  height: 12px;
`;

const ArrowImg = styled.img`
  width: 8px;
  height: 8px;
`;

let tankName, registrationId, serialNumber;
const TankCard = memo(
  ({
    isactive,
    tank,
    click,
    remove,
    editTank,
    toggleRegistrationCode,
    isAlert,
    setRawRegisterValue,
    setRegisterValuesFlag,
    deviceType,
    setTankSettings,
    setLiveAlerts,
  }) => {
    const [temperature, setTemperature] = useState("--");
    const [tankType, setTankType] = useState(null);
    const [volume, setVolume] = useState("--");
    const [firstHSP, setFirstHSP] = useState("--");
    const [lastHSP, setLastHSP] = useState("--");
    const [firstLSP, setFirstLSP] = useState("--");
    const [lastLSP, setLastLSP] = useState("--");
    const { t } = useTranslation();
    const user = getUser();
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1200 });
    io.socket.on(`influxData:${tank.id}`, (data) => {
      if(deviceType === "HPF") {
        setTemperature(data.HPF[Object.keys(data.HPF)[0]].HPF_INPUT_REG_TEMP1);
        setVolume(
          data.HPF[Object.keys(data.HPF)[0]]
            .HPF_INPUT_REG_VOLUME_4TO20MA_READING ||
            Number(
              data.HPF[Object.keys(data.HPF)[0]]
                .HPF_INPUT_REG_VOLUME_4TO20MA_READING
            ) === 0
            ? data.HPF[Object.keys(data.HPF)[0]]
              .HPF_INPUT_REG_VOLUME_4TO20MA_READING
            : "--"
        );
      } else {
        const totalEvcs = Object.keys(data.EVC).length;
        setTemperature(data.CHILLER[Object.keys(data.CHILLER)[0]].CHILLER_CONTROLLER_INPUT_REG_TEMP_1);
        if(data.EVC && totalEvcs){
          const tempSCTFirstObj = getPressureAsPerSelectedUnitSystem(Number(data.EVC[Object.keys(data.EVC)[0]].EVC_INPUT_REG_HI_SAT_TEMP));
          const tempSSTFirstObj = getPressureAsPerSelectedUnitSystem(Number(data.EVC[Object.keys(data.EVC)[0]].EVC_INPUT_REG_LO_SAT_TEMP));
          const tempSCTLastObj = getPressureAsPerSelectedUnitSystem(Number(data.EVC[Object.keys(data.EVC)[totalEvcs - 1]].EVC_INPUT_REG_HI_SAT_TEMP));
          const tempSSTLastObj = getPressureAsPerSelectedUnitSystem(Number(data.EVC[Object.keys(data.EVC)[totalEvcs - 1]].EVC_INPUT_REG_LO_SAT_TEMP));
          setFirstHSP(`${tempSCTFirstObj.pressure} ${tempSCTFirstObj.pressureUnit}`);
          setFirstLSP(`${tempSSTFirstObj.pressure} ${tempSSTFirstObj.pressureUnit}`);
          setLastHSP(`${tempSCTLastObj.pressure} ${tempSCTLastObj.pressureUnit}`);
          setLastLSP(`${tempSSTLastObj.pressure} ${tempSSTLastObj.pressureUnit}`);
        }
      }
      setTankType(data.tankType);
    });

    const { temperature: tempValue, tempUnit } = getTempAsPerSelectedUnitSystem(
      Number(temperature)
    );

    const {
      volume: volumeValue,
      volumeUnit,
    } = getVolumeAsPerSelectedUnitSystem(Number(volume));

    if(deviceType === "HPF") {
      tankName = tank.tankName;
      registrationId = tank.registrationId;
      serialNumber = tank.HPFSerialNumber;
    } else if(deviceType === "CHILLER") {
      tankName = tank.deviceName;
      registrationId = tank.deviceRegistrationId;
      serialNumber = tank.deviceSerialNumber;
    }
    // const { tankName, registrationId, HPFSerialNumber } = tank;
    return (
      <>
        {!registrationId ? (
          <Card className={isDesktopOrLaptop ? 'mb-3 border-0' : 'mb-3 border'} key={tank.id}>
            <CardBody
              isactive={isactive}
              onClick={e => {
                e.stopPropagation();
                click();
              }}
              isAlert={isAlert}
              className="d-flex align-items-center"
            >
              {!['Sanitarian', 'MilkHauler'].includes(user.role) && (
                <TankOptions className="border-0">
                  <BootstrapDropdown onClick={e => e.stopPropagation()}>
                    <BootstrapDropdown.Toggle variant="none" id="Dropdown-basic" className="p-0">
                      <Image src={Dot} />
                    </BootstrapDropdown.Toggle>
                    <BootstrapDropdown.Menu
                      menualign="right"
                      flip={false}
                      id="dropdown-menu-align-right"
                      className="edit-dropdown rounded-1 border-0 p-0"
                    >
                      <BootstrapDropdown.Item
                        variant="link"
                        className="tank-option-btn rounded-0  text-capitalize text-left w-100"
                        onClick={e => {
                          e.stopPropagation();
                          editTank();
                        }}
                      >
                        {t('edit')}
                      </BootstrapDropdown.Item>
                      <BootstrapDropdown.Item
                        variant="link"
                        className="tank-option-btn rounded-0 text-capitalize text-left w-100"
                        onClick={e => {
                          e.stopPropagation();
                          remove();
                        }}
                      >
                        {t('delete')}
                      </BootstrapDropdown.Item>
                      {serialNumber !== '' && (
                        <BootstrapDropdown.Item
                          variant="link"
                          className="tank-option-btn rounded-0 text-capitalize text-left w-100 text-truncate"
                          onClick={e => {
                            e.stopPropagation();
                            toggleRegistrationCode();
                          }}
                        >
                          {registrationId === '' ? t('re_register') : t('cancel_re_register')}
                        </BootstrapDropdown.Item>
                      )}
                      <BootstrapDropdown.Item
                        variant="link"
                        className="tank-option-btn rounded-0 text-capitalize text-left w-100"
                        onClick={e => {
                          e.stopPropagation();
                          setRawRegisterValue(true);
                        }}
                      >
                        {t('raw_register')}
                      </BootstrapDropdown.Item>
                      <BootstrapDropdown.Item
                        variant="link"
                        className="tank-option-btn rounded-0 text-capitalize text-left w-100"
                        onClick={e => {
                          e.stopPropagation();
                          setRegisterValuesFlag(true);
                        }}
                      >
                        {t('register_values')}
                      </BootstrapDropdown.Item>
                      {
                        deviceType === "CHILLER" &&                       
                        <BootstrapDropdown.Item
                          variant="link"
                          className="tank-option-btn rounded-0 text-capitalize text-left w-100"
                          onClick={e => {
                            e.stopPropagation();
                            setTankSettings(true);
                            setLiveAlerts(false);
                          }}
                        >
                          {t('setting_parameter')}
                        </BootstrapDropdown.Item>
                      }
                    </BootstrapDropdown.Menu>
                  </BootstrapDropdown>
                </TankOptions>
              )}
              <div>
                {tankType === null ? (
                  <div className="active offline offlineTank">
                    <span className="tankStatusName" style={{ width: '40px' }}>
                      --
                    </span>
                  </div>
                ) : tankType === '1' ? (
                  <span className={isAlert ? 'vertical-tank-red-indicator position-relative' : ''}>
                    <TankCardImg src={tankVerticalIcon} alt="" />
                  </span>
                ) : (
                      <span className={isAlert ? 'tank-red-indicator position-relative' : ''}>
                        <TankCardImg src={deviceType === "HPF" ? horizontalTank : tankIcon} alt="" />
                      </span>
                    )}
              </div>

              <div className="mx-2 text-truncate">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="tooltip-disabled" className="annotation-tooltip">
                      {tankName}
                    </Tooltip>
                  }
                >
                  <div className="mr-2 text-truncate">{tankName}</div>
                </OverlayTrigger>
                 { deviceType === "HPF" ? 
                    <p className="text-muted text-truncate m-0">{`${tempValue} ${tempUnit} | ${volumeValue} ${volumeUnit}`} </p>
                  :                 
                    <TankInformation>
                      <GycolInfo>
                        <GycolImg src={gycolIcon} alt="" />
                        <span>{firstHSP}</span>
                        <ArrowImg src={arrowIcon} alt="" />
                        <span>{lastHSP}</span>
                      </GycolInfo>
                      <ProductInfo>
                        <ProductImg src={productIcon} alt="" />
                        <span>{firstLSP}</span>
                        <ArrowImg src={arrowIcon} alt="" />
                        <span>{lastLSP}</span>
                      </ProductInfo>
                    </TankInformation>
                }
              </div>
            </CardBody>
          </Card>
        ) : (
            <Card
              style={isAlert ? { "background-color": "#ffe8e8" } : null}
              className={isDesktopOrLaptop ? "mb-3 border-0" : "mb-3 border"}>
              {!['Sanitarian', 'MilkHauler'].includes(user.role) && (
                <TankOptions className="border-0">
                  <BootstrapDropdown onClick={e => e.stopPropagation()}>
                    <BootstrapDropdown.Toggle variant="none" id="Dropdown-basic" className="p-0">
                      <Image src={Dot} />
                    </BootstrapDropdown.Toggle>
                    <BootstrapDropdown.Menu
                      menualign="right"
                      flip={false}
                      id="dropdown-menu-align-right"
                      className="edit-dropdown rounded-1 border-0 p-0"
                    >
                      <Button
                        variant="link"
                        className="tank-option-btn rounded-0  text-capitalize text-left w-100"
                        onClick={e => {
                          e.stopPropagation();
                          editTank();
                        }}
                      >
                        {t('edit')}
                      </Button>
                      <Button
                        variant="link"
                        className="tank-option-btn rounded-0 text-capitalize text-left w-100"
                        onClick={e => {
                          e.stopPropagation();
                          remove();
                        }}
                      >
                        {t('delete')}
                      </Button>
                      {serialNumber !== '' && (
                        <Button
                          variant="link"
                          className="tank-option-btn rounded-0 text-capitalize text-left w-100 text-truncate"
                          onClick={e => {
                            e.stopPropagation();
                            toggleRegistrationCode();
                          }}
                        >
                          {registrationId === '' ? t('re_register') : t('cancel_re_register')}
                        </Button>
                      )}
                    </BootstrapDropdown.Menu>
                  </BootstrapDropdown>
                </TankOptions>
              )}
              <UnregTankCard>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="tooltip-disabled" className="annotation-tooltip">
                      {tankName}
                    </Tooltip>
                  }
                >
                  <span>{tankName.length > 13 ? `${tankName.substring(0, 10)}...` : `${tankName}`}</span>
                </OverlayTrigger>
                <p className="m-0">{registrationId}</p>
              </UnregTankCard>
            </Card>
          )}
      </>
    );
  }
);

TankCard.propTypes = {
  tank: PropTypes.any,
  onClick: PropTypes.func,
  isactive: PropTypes.bool,
  remove: PropTypes.func.isRequired,
  editTank: PropTypes.func.isRequired,
};
TankCard.defaultProps = {
  tank: {},
  isactive: false,
};

export default TankCard;

const socketIOClient = require("socket.io-client");
const sailsIOClient = require("sails.io.js");
const io = sailsIOClient(socketIOClient);

io.sails.url = process.env.DOMAIN || 'http://35.167.162.138:1338';
io.sails.headers = {
  token: localStorage.getItem("token"),
};
io.sails.autoConnect = true;
io.sails.reconnection = true;
export default io;

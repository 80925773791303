import React, { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import io from "../../actions/socket";
import { rootApi } from "../../axiosConfig";
import { Row, Col, Image } from "react-bootstrap";
import _ from "lodash";
import moment from "moment-timezone";
import InfiniteScroll from "react-infinite-scroll-component";
import Time from "../../assets/images/time.svg";
import ResolvedTime from "../../assets/images/resolved-time.svg";
import HPF from "../../assets/images/tank-new.png";
import EVC_GIF from "../../assets/images/EVC-fan-gif.gif";
import EVC from "../../assets/images/EVC-fan.png";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";
import { registerNames } from "../../actions/passwordRegex";

const LiveAlertsNotifications = memo(({ currentTankData, dropdownTime }) => {
  const { dairyID, tankID } = useParams();
  const { t } = useTranslation();
  const [liveAlerts, setLiveAlerts] = useState([]);
  const [resolvedAlerts, setResolvedAlerts] = useState([]);
  const [currentCount, setCurrentCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [countAlert, setCountAlert] = useState(false);
  let liveAlertCount = 0;
  const deviceType = localStorage.getItem("deviceType");
  /** Live alerts Api call */
  useEffect(() => {
    if(deviceType === "HPF"){
      rootApi
        .get(
          `alerthistories?where={"and":[{"alert":null},{"dairy":"${dairyID}"},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`
        )
        .then((response) => {
          setLiveAlerts(response.data);
        })
        .catch((err) => {
          toast.dark(
            <Toaster
              icon="error"
              message={err ?? t("live_alert_history_error")}
            />
          );
        });
      /** live alert using socket connection  */
      io.socket.on(`alerthistory:manual:${dairyID}`, (data1) => {
        if (data1["isResolved"] === false) {
          setCountAlert(true);
        } else {
          rootApi
            .get(
              `alerthistories?where={"and":[{"alert":null},{"dairy":"${dairyID}"},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`
            )
            .then((response) => {
              setCountAlert(response.data.length > 0 ? true : false);
              setLiveAlerts(response.data);
            })
            .catch((err) => {
              toast.dark(
                <Toaster
                  icon="error"
                  message={err?.response?.message ?? t("alert_history_error")}
                />
              );
            });
        }
      });
    }else if(deviceType === "CHILLER"){
      rootApi
        .get(
          `chilleralerthistories?where={"and":[{"chillerAlert":null},{"location":"${dairyID}"},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`
        )
        .then((response) => {
          setLiveAlerts(response.data);
        })
        .catch((err) => {
          toast.dark(
            <Toaster
              icon="error"
              message={err ?? t("live_alert_history_error")}
            />
          );
        });
      /** live alert using socket connection  */
      io.socket.on(`chilleralerthistory:manual:${dairyID}`, (data1) => {
        if (data1["isResolved"] === false) {
          setCountAlert(true);
        } else {
          rootApi
            .get(
              `chilleralerthistories?where={"and":[{"chillerAlert":null},{"location":"${dairyID}"},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`
            )
            .then((response) => {
              setCountAlert(response.data.length > 0 ? true : false);
              setLiveAlerts(response.data);
            })
            .catch((err) => {
              toast.dark(
                <Toaster
                  icon="error"
                  message={err?.response?.message ?? t("alert_history_error")}
                />
              );
            });
        }
      });
    }

  }, []);
  /** Resolved alerts api call */

  const fetchResolvedAlertData = () => {
    setLoader(true);
    if(deviceType === "HPF") {
      rootApi
      .get(
        `/alerthistories?where={"and":[{"alert":null},{"tank":"${tankID}"},{"isResolved":true}]}&sort=updatedAt DESC&limit=5&skip=${currentCount}`
      )
      .then((response) => {
        const result = response.data;
        setResolvedAlerts([...resolvedAlerts, ...result]);
        setCurrentCount((prev) => prev + 5);
        setLoader(false);
      })
      .catch((err) => {
        toast.dark(
          <Toaster
            icon="error"
            message={err ?? t("live_alert_history_error")}
          />
        );
      });
    } else if(deviceType === "CHILLER") {
      rootApi
      .get(
        `/chilleralerthistories?where={"and":[{"chillerAlert":null},{"device":"${tankID}"},{"isResolved":true}]}&sort=updatedAt DESC&limit=5&skip=${currentCount}`
      )
      .then((response) => {
        const result = response.data;
        setResolvedAlerts([...resolvedAlerts, ...result]);
        setCurrentCount((prev) => prev + 5);
        setLoader(false);
      })
      .catch((err) => {
        toast.dark(
          <Toaster
            icon="error"
            message={err ?? t("live_alert_history_error")}
          />
        );
      });
    }
  };

  useEffect(() => {
    fetchResolvedAlertData();
  }, []);
  return (
    <div className="my-4 rounded px-4 py-3 live-alerts">
      <div className="mb-3">
        <h3 className="text-dark px-3 pt-2">{t("live_alerts")}</h3>
        {liveAlerts.map((liveAlert) => {
          if (
            liveAlert.tank !== null &&
            (liveAlert.tank.id || (liveAlert.tank && countAlert === true)) ===
            tankID
          ) {
            const Triggered = new Date(liveAlert.createdAt);
            liveAlertCount += 1;

            // evc and hpf indication names ends here
            let indicator =
              liveAlert.deviceType === "EVC" && currentTankData !== undefined
                ? currentTankData.EVC
                : "";

            let indicatorName;
            if (indicator) {
              indicator = indicator[liveAlert.deviceID]
                ? Number(indicator[liveAlert.deviceID].EVC_IDENTIFICATION)
                : "";
              indicatorName =
                indicator === 0 ? "EVC" : _.invert(registerNames)[indicator];
            }
            if (liveAlert.deviceType === "HPF") {
              indicatorName = "HPF";
            } else if (indicatorName === "") {
              indicatorName = "EVC";
            }
            return (
              <Row className="border-bottom p-3" key={liveAlert.id}>
                <Col lg={1} xs={3} md={2}>
                  <Image
                    src={liveAlert.deviceType === "HPF" ? HPF : EVC_GIF}
                    fluid
                    className="w-100"
                  />
                  <div className="text-center mt-1">
                    <small>{indicatorName}</small>
                  </div>
                </Col>
                <Col lg={11} xs={9} md={10}>
                  <div className="text-danger text-uppercase mr-4">
                    {liveAlert.configDisplay}
                  </div>
                  <p className="mb-1">{liveAlert.alertText}</p>
                  <div>
                    <span>
                      <Image src={Time} className="mr-2" />
                      {t("triggered")}:
                    </span>{" "}
                    <small>
                      {dropdownTime === undefined
                        ? moment(Triggered).format("hh:mm:ss A (DD MMM YYYY)")
                        : moment
                          .tz(new Date(Triggered), dropdownTime)
                          .format("hh:mm:ss A (DD MMM YYYY)")}
                      {/* {moment(Triggered).format("hh:mm:ss A (DD MMM YYYY)")} */}
                    </small>
                  </div>
                </Col>
              </Row>
            );
          } else {
            return "";
          }
        })}
        {liveAlertCount === 0 && (
          <div className="px-3 mt-3">{t("live_alert_not_found")}</div>
        )}
      </div>
      <div className="mb-3 mt-5">
        <h3 className="text-dark px-3 pt-2">{t("resolved_alerts")}</h3>
        <InfiniteScroll
          style={{ overflow: "hidden" }}
          dataLength={resolvedAlerts.length}
          next={fetchResolvedAlertData}
          hasMore={true}
          loader={
            loader === true ? (
              <div className="text-center mt-5">{t("loading_text")}</div>
            ) : resolvedAlerts.length === 0 ? (
              <div className="px-3 mt-3">{t("resolved_alert_not_found")}</div>
            ) : (
                  ""
                )
          }
        >
          {resolvedAlerts.map((historyAlert, i) => {
            if (
              historyAlert.tank !== null &&
              historyAlert.isResolved === true
            ) {
              const Triggered = new Date(historyAlert.createdAt);
              const Resolved = new Date(historyAlert.updatedAt);
              let indicator =
                historyAlert.deviceType === "EVC" &&
                  currentTankData !== undefined
                  ? currentTankData.EVC
                  : "";

              let indicatorName = "";
              if (indicator) {
                indicator = indicator[historyAlert.deviceID]
                  ? Number(indicator[historyAlert.deviceID].EVC_IDENTIFICATION)
                  : "";
                indicatorName =
                  indicator === 0 ? "EVC" : _.invert(registerNames)[indicator];
              }
              if (historyAlert.deviceType === "HPF") {
                indicatorName = "HPF";
              } else if (indicatorName === "") {
                indicatorName = "EVC";
              }
              return (
                <Row className="border-bottom p-3" key={i}>
                  <Col lg={1} xs={3} md={2}>
                    <Image
                      src={historyAlert.deviceType === "HPF" ? HPF : EVC}
                      fluid
                      className="w-100"
                    />
                    <div className="text-center mt-1">
                      <small>{indicatorName}</small>
                    </div>
                  </Col>
                  <Col lg={11} xs={9} md={10}>
                    <div className="text-uppercase mr-4">
                      {historyAlert.configDisplay}
                    </div>
                    <p className="mb-1">{historyAlert.alertText}</p>
                    <Row>
                      <Col xl={5} lg={5} md={6} sm={12} xs={12}>
                        <span>
                          <Image src={Time} className="mr-2" /> {t("triggered")}
                          :
                        </span>{" "}
                        <small>
                          {dropdownTime === undefined
                            ? moment(Triggered).format(
                              "hh:mm:ss A (DD MMM YYYY)"
                            )
                            : moment
                              .tz(new Date(Triggered), dropdownTime)
                              .format("hh:mm:ss A (DD MMM YYYY)")}
                        </small>
                      </Col>
                      <Col xl={5} lg={5} md={6} sm={12} xs={12}>
                        <span>
                          <Image src={ResolvedTime} className="mr-2" />
                          {t("resolved")}:
                        </span>{" "}
                        <small>
                          {dropdownTime === undefined
                            ? moment(Resolved).format(
                              "hh:mm:ss A (DD MMM YYYY)"
                            )
                            : moment
                              .tz(new Date(Resolved), dropdownTime)
                              .format("hh:mm:ss A (DD MMM YYYY)")}
                        </small>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            }
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
});

export default LiveAlertsNotifications;
